import React from "react";
import Footer from "app/components/shared/Footer/Footer";
import Navbar from "app/components/shared/Navbar/Navbar.tsx";
import Container from '@mui/material/Container';
import logo from 'app/assets/images/logo.png';
import "./AboutUs.scss";

export default function AboutUs() {
  return (
    <>
      <Navbar />
      <Container >
        <div className="about-us">
          <div className="logo-title">
           <img src={logo} alt="" width="140"/> <div className="font-1 main-color ">We help you achieve your goals</div>
          </div>
          <div className="v-space-10"></div>
          <div className="v-space-10"></div>
          <div className="title">ABOUT US</div>
          <div className="text">
            <p>At Competency Edge, we are dedicated to fostering a future where students graduate not just with knowledge, but with the competencies needed to succeed and make meaningful contributions in any environment. As three academics from diverse backgrounds, we founded Competency Edge with a shared passion for enhancing education. 
            </p>
            <p>We believe that education should be intentional in its design, transparent in its offering, tangible in its learning, and visible in its outcomes. Our approach focuses on competency-oriented education—combining the best of traditional and competency-based models to align individual performance with the broader expectations of educational, economic, and social systems.</p>
            <p>Through Competency Edge, we introduce a structured method for translating courses into essential competencies, designing curricula that ensure students, educators, and employers work together to guarantee relevance and real-world application.</p>
          </div>
          <div className="v-space-10"></div>
          <div className="v-space-10"></div>
        </div>
      </Container>
      <Footer />
    </>
  );
}

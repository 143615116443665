import Api from 'app/helpers/api'

export const universitiesSlice = ((set, get) => ({
  universities: [],

  actions: {
    fetchUniversities: async (params) => {
      const api = new Api();
      api.getUniversities()
        .then((response) => {
          set((state) => ({ universities: response.data.universities}));
        })
      .catch((err) => console.log(err));
    },

  }

}))
import React from "react"
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import {AbilityFillingStepEnum, CollaborateViewEnum} from "app/components/shared/enums/global-enums.tsx"
import {useTranslation} from 'react-i18next'
import useStore from 'app/store/store'

import './TickItems.scss'

export default function TickItems ({setOpenLC}){

  const {t} = useTranslation();
  const fAS = useStore((state) => state.fillingAbilitiesStep);
  const cV = useStore((state) => state.collaborateView);
  const programs = useStore((state) => state.programs);
  const instructorActions = [...useStore((state) => state.instructorActions)];

  // const allDisciplines = [...new Set(disciplines.map((discipline)=>{ 
  //   let elm = {};
  //   elm.label = discipline.name;
  //   elm.id = discipline.id;
  //   return elm;
  // }))];

  let sortedIA = [];
  if (instructorActions.length>0){
    sortedIA = instructorActions.slice(0,-1).sort((a, b) => {return a.name.localeCompare(b.name) });;
    sortedIA.push(instructorActions[instructorActions.length-1])
  }
  const storeActions = useStore((state) => state.actions);

  function checkItem(event, step){
    event.preventDefault();
    storeActions.setFillingAbilitiesStep(step);
  }

  function checkView(event, view){
    event.preventDefault();
    storeActions.setCollaborateView(view);

    if (view === CollaborateViewEnum.VIEW_4){
      setOpenLC(true);
    }
  }

  return (
    <Grid   className="d-f fd-r jc-r tick-items">
      <Grid item style={{marginRight:15}}>
        <Grid className="d-f ai-c">
          
          <Checkbox sx={{p:0}}  className="ch-third" checked={!!(fAS === AbilityFillingStepEnum.STEP_1)}   onClick={(event) => checkItem(event, AbilityFillingStepEnum.STEP_1)}    /> 
          
          <Typography variant="span" sx={{fontSize: 14, fontWeight: 700}} className="second-color font-1 ">
            {t("Skills")}
          </Typography>

        </Grid>

      </Grid>
      <Grid item style={{marginRight:15}}>
        <Grid className="d-f ai-c">
          
          <Checkbox sx={{p:0}}  className="ch-fifth" checked={!!(fAS === AbilityFillingStepEnum.STEP_2)} onClick={(event) => checkItem(event, AbilityFillingStepEnum.STEP_2)}  />
          
          <Typography variant="span" sx={{fontSize: 14, fontWeight: 700}} className="second-color font-1 ">
            {t("Attitudes")}
          </Typography>


        </Grid>
      </Grid>

      <Grid item style={{marginRight:15}}>
        <Grid className="d-f ai-c">
          
          <Checkbox sx={{p:0}}  className="ch-faded" checked={!!(cV === CollaborateViewEnum.VIEW_1)} onClick={(event) => checkView(event, CollaborateViewEnum.VIEW_1)}  />
          
          <Typography variant="span" sx={{fontSize: 14, fontWeight: 700}} className="second-color font-1 ">
            {t("My overview")}
          </Typography>

        </Grid>
        <Grid className="d-f fd-c" container>
          <Grid item >
          <Checkbox sx={{p:0}}  className="ch-faded" checked={!!(cV === CollaborateViewEnum.VIEW_2)} onClick={(event) => checkView(event, CollaborateViewEnum.VIEW_2)}  />
          
          <Typography variant="span" sx={{fontSize: 14, fontWeight: 700}} className="second-color font-1 ">
            {t("Program View")}
          </Typography>
          </Grid>
          <Grid item >
          {
            !!(cV === CollaborateViewEnum.VIEW_2 && programs.length>0) &&
            <>
              <div className="v-space-2"></div>
              <Autocomplete
                id="combo-box-demo"
                options={programs}
                sx={{ width: 300 }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label={t("Program's Name")} />}
                onChange={(event, value) => storeActions.setProgram(value)}
                  />
              <div className="v-space-2"></div>
            </>
          }
          </Grid>
        </Grid>


        <Grid className="d-f ai-c">
          
          <Checkbox sx={{p:0}}  className="ch-faded" checked={!!(cV === CollaborateViewEnum.VIEW_4)} onClick={(event) => checkView(event, CollaborateViewEnum.VIEW_4)}  />
          
          <Typography variant="span" sx={{fontSize: 14, fontWeight: 700}} className="second-color font-1 ">
            {t("Customize")}
          </Typography>

        </Grid>
      </Grid>
    </Grid>
  )
}
import React, {useRef, useState, useEffect} from "react"
import Grid from '@mui/material/Grid';
import {useSearchParams} from 'react-router-dom';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { useDebouncedCallback } from "use-debounce";

import TickItems from "app/components/Courses/CourseVisualisation/TickItems/TickItems"
import SelectedAbilitiesDetails from "app/components/Abilities/SelectedAbilitiesDetails/SelectedAbilitiesDetails"
import DynamicPartial from "app/components/Courses/CourseVisualisation/DynamicPartial/DynamicPartial"
import cssVars from 'app/helpers/_variables.module.scss';
import { Stage, Layer, Text, TextPath, Circle, Group } from 'react-konva';
import {useTranslation} from 'react-i18next'

import {AbilityFillingStepEnum} from "app/components/shared/enums/global-enums.tsx"

import useStore from 'app/store/store'
import './CourseVisualisation.scss'

export default function CourseVisualisation ({stageRef, fxWidth, fxHeight, typeFor}){

  const stageContainer = useRef();
  let [searchParams, setSearchParams] = useSearchParams()
  const {t} = useTranslation();
  let fAS = useStore((state) => state.fillingAbilitiesStep);

  if (typeFor) fAS = typeFor;

  let selectedAbilities = [...useStore((state) => state.selectedAbilities)];
  selectedAbilities = selectedAbilities.sort((a, b) => parseInt(a.id) - parseInt(b.id));
  const knowledges = useStore((state) => state.knowledges);
  const courseSkills = useStore((state) => state.courseSkills);
  const courseAttitudes = useStore((state) => state.courseAttitudes);
  const courseSkillApps = useStore((state) => state.courseSkillApplications);
  const courseAttitudeApps = useStore((state) => state.courseAttitudeApplications);

  let mainColor1, mainColor2, mainColor3, courseElms, unqCourseElms, courseElmApps, aiColors;
  if (fAS === AbilityFillingStepEnum.STEP_1){
    mainColor1 = cssVars["third-color"]; mainColor2 = cssVars["third-color-faded-3"]; mainColor3 = cssVars["third-color-faded-4"];
    courseElms = [...courseSkills];
    courseElmApps = [...courseSkillApps];

    aiColors = {colorE: cssVars["third-color-e"], colorI: cssVars["third-color-i"], colorT: cssVars["third-color-t"], colorA: cssVars["fourth-color-faded-3"]}
  }
  if (fAS === AbilityFillingStepEnum.STEP_2){
    mainColor1 = cssVars["fifth-color"]; mainColor2 = cssVars["fifth-color-faded-3"]; mainColor3 = cssVars["fifth-color-faded-4"];
    courseElms = [...courseAttitudes];
    courseElmApps = [...courseAttitudeApps];

    aiColors = {colorE: cssVars["fifth-color-e"], colorI: cssVars["fifth-color-i"], colorT: cssVars["fifth-color-t"], colorA: cssVars["fourth-color-faded-3"]}
  }

  courseElms = courseElms.sort((cSa, cSb) => parseInt(cSa[fAS].id) - parseInt(cSb[fAS].id));

  unqCourseElms = [...new Map(courseElms.map(cS => [cS[fAS].id, cS[fAS]])).values()];

  const [cWidth, setCWidth] = useState(null);
  const [cHeight, setCHeight] = useState(null);

  let w, h, center, kernelRad = 55;
  const kngsCir = [160, 210, 260], kngsCols = [mainColor3, mainColor2, mainColor1];
  if (stageContainer.current){
    w = stageContainer.current.offsetWidth;
    h = stageContainer.current.offsetHeight;
    center = [cWidth/2, cHeight/2];
  }


  function hideVisualisation() {
    searchParams.delete("visualisation");
    setSearchParams(searchParams);
  }

  function changeWidthAndHeight() {
    w = stageContainer.current.offsetWidth;
    h = stageContainer.current.offsetHeight;
    setCWidth(w); setCHeight(h);
  }

  const handleResize = useDebouncedCallback(
    changeWidthAndHeight,
    500
  );

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return _ => {
      window.removeEventListener('resize', handleResize);
    }
  }, [handleResize]);

  return (
    <Grid container className="p-r w-100 ">
      <div className="close-panel" onClick={hideVisualisation}> 
        <CloseIcon />
      </div>
      <Grid item md={8} className="w-100" >
        <Box className="w-100 p-r" ref={stageContainer} style={{height: fxHeight? fxHeight+'px': '100%'}}>

          <Grid className="tick-items">
            <TickItems />
          </Grid>
          {
            cWidth && 
            <Stage width={fxWidth||cWidth} height={fxHeight||cHeight} ref={stageRef}>
            <Layer>
              
              {
                knowledges.map((knowledge, key) => {
                  const R = kngsCir[key]-20;
                  return (
                    <Group key={key}>
                      <Circle x={center[0]} y={center[1]} radius={kngsCir[key]} stroke="#999" strokeWidth={2} />
                      <TextPath 
                        width={200} align="center" fontSize={16} fontFamily={cssVars["font-2"]}
                        text={" "+knowledge.name.charAt(0).toUpperCase() + knowledge.name.slice(1) }
                        x={center[0]} y={center[1]}
                        data={'M 0, 0 m -'+R+', 0 a '+R+','+R+' 0 1,1 '+R*2+',0'}
                        fill="black" strokeWidth={0} rotation={-50}
                      />
                    </Group>
                    )
                })
              }

              <DynamicPartial selectedAbilities={selectedAbilities} courseElms={courseElms} courseElmApps={courseElmApps} aiColors={aiColors} kngsCols={kngsCols} unqCourseElms={unqCourseElms} knowledges={knowledges} center={center} kngsCir={kngsCir} mainColor1={mainColor1} fAS={fAS} />
              
              <Circle x={center[0]} y={center[1]} radius={kernelRad} fill={mainColor1} />

              <Text text={fAS.toUpperCase()+ " "+ t("MAP")} align="center" verticalAlign="middle" color="white" width={kernelRad+20} height={kernelRad+20} fill="white" x={center[0]} y={center[1]} fontSize={15} fontStyle="bold" fontFamily={cssVars["font-2"]} offsetX={(kernelRad+20)/2} offsetY={(kernelRad+20)/2} />
            </Layer>
          </Stage>
          }
        </Box>

      </Grid>
      <Grid item md={4} className="h-100 hide-small-screen">
        <SelectedAbilitiesDetails selectedAbilities={selectedAbilities} unqCourseElms={unqCourseElms} fAS={fAS} />
      </Grid>
    </Grid>
    
    );
}
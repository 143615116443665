export const AppRoutes = {
  newCoursePath: "/courses/new",
  courseAbilitiesPath: "/courses/:courseId/abilities",
  editCoursePath: "/courses/:courseId/edit",
  courseReportPath: "/courses/:courseId/report",
  dashboardPath: "/dashboard",
  agendaPath: "/agenda",
  collaboratePath: "/collaborate",
  signUpPath: "/users/new",
  signInPath: "/sessions/new",
  aboutUsPath: "/about-us",
}
import React, {useEffect} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "app/components/Home/Home.jsx";
import AboutUs from "app/components/AboutUs/AboutUs.jsx";
import CoursesList from "app/components/Courses/CoursesList/CoursesList";
import Collaborate from "app/components/Collaborate/Collaborate";
import ProtectedRoute from "app/components/shared/ProtectedRoute/ProtectedRoute";
import CourseDetails from "app/components/Courses/CourseDetails/CourseDetails";
import CourseReport from 'app/components/Courses/CourseReport/CourseReport';
import SignUp from 'app/components/Users/SignUp/SignUp';
import SignIn from 'app/components/Users/SignIn/SignIn';
import CircularProgress from '@mui/material/CircularProgress';
import AbilitiesCover from "app/components/Abilities/AbilitiesCover/AbilitiesCover";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {AppRoutes} from "app/helpers/routes";
import useStore from "app/store/store";
import 'app/helpers/i18n';
import "./App.scss";


function App() {

  const snackMessageShown = useStore((state)=> state.snackMessageShown);
  const successMessage = useStore((state)=> state.successMessage);
  const errorMessage = useStore((state)=> state.errorMessage);
  const infoMessage = useStore((state)=> state.infoMessage);
  const warningMessage = useStore((state)=> state.warningMessage);
  const isPendingMessage = useStore((state)=> state.isPendingMessage);

  const storeActions = useStore((state)=> state.actions);

  let sbProps = {}; 
  if (isPendingMessage){
    sbProps.icon = false;
  }

  useEffect(() =>{
    storeActions.reloadUser();
  }, [storeActions]);

  return (
    <>
    <BrowserRouter>
      <Routes>
      
        <Route index element={<Home />}></Route>


        <Route path={AppRoutes.dashboardPath} element={<ProtectedRoute elm={<CoursesList />}/>}></Route>
        <Route path={AppRoutes.newCoursePath} element={<ProtectedRoute elm={<CourseDetails />}/>}></Route>
        <Route path={AppRoutes.courseAbilitiesPath} element={<ProtectedRoute elm={<AbilitiesCover />}/>}></Route>
        <Route path={AppRoutes.editCoursePath} element={<ProtectedRoute elm={<CourseDetails />}/>}></Route>
        <Route path={AppRoutes.courseReportPath} element={<ProtectedRoute elm={<CourseReport />}/>}></Route>
        <Route path={AppRoutes.collaboratePath} element={<ProtectedRoute elm={<Collaborate />}/>}></Route>
      
        <Route path={AppRoutes.signUpPath} element={<SignUp />}></Route>
        <Route path={AppRoutes.signInPath} element={<SignIn />}></Route>
        
        <Route path={AppRoutes.aboutUsPath} element={<AboutUs />}></Route>
      
      </Routes>
    </BrowserRouter>
    
      <Snackbar
        open={!!snackMessageShown}
        anchorOrigin={{ vertical: 'bottom', horizontal:'right' }}
        container={document.body}
      >
        {
          (successMessage || infoMessage || errorMessage || warningMessage) &&
          <Alert  {...sbProps} severity={successMessage? "success": (errorMessage? "error" : (infoMessage? "info" : 'warning'))} variant="filled"  sx={{ width: '100%', fontSize: 18 }}>
            { isPendingMessage && <CircularProgress className="color-white"  color="inherit" size="1rem"/> }
            {' '}
            {successMessage || errorMessage || warningMessage || infoMessage}
          </Alert>
        }
      </Snackbar>

    
    </>
  );
}

export default App;

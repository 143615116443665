import Api from 'app/helpers/api'
import i18n from 'app/helpers/i18n'
import {produce} from 'immer'
import {AbilityFillingStepEnum} from "app/components/shared/enums/global-enums.tsx"

export const abilitiesSlice = ((set, get) => ({
  abilities: [],
  selectedAbilities: [],
  

  actions: {
    fetchAbilities: async (params) => {
      const api = new Api();
      api.getAbilities()
        .then((response) => {
          set((state) => ({ abilities: response.data.abilities }));
          get().actions.refreshSelectedAbilities();
        })
      .catch((err) => console.log(err));
    },

    goNextAbilityOrSave: ()=>{
      const selectedAbilities = [...get().selectedAbilities];
      for (var i = 0; i < selectedAbilities.length; i++) {
        if (get().actions.isUncompletedAbility(selectedAbilities[i])) {
          get().actions.pushAbilityToTop(selectedAbilities[i]);
          return;
        }
      }
      get().actions.updateCourseAbilities();
      
    },

    isUncompletedAbility: (ability)=>{
      let abilityNotCompleted = false;

      let relSkills = get().courseSkills.filter(elm => elm.skill.ability_id === ability.id);
      let relAttitudes = get().courseAttitudes.filter(elm => elm.attitude.ability_id === ability.id);

      if (relSkills.length === 0 && relAttitudes.length === 0){
        abilityNotCompleted = true;
      }

      return abilityNotCompleted;
    },

    pushAbilityToTop: (ability) => {
      let selectedAbilities = [...get().selectedAbilities];
      let AbInx = selectedAbilities.findIndex((elm)=> elm.id ===ability.id);
      let lastItem = selectedAbilities.splice(AbInx, 1)[0];

      if (lastItem !== undefined) selectedAbilities.unshift(lastItem);
      set((state) => ({ selectedAbilities: selectedAbilities }));
    },

    toggleSelectAbility: (ability) => {
      let selectedAbilities = [...get().selectedAbilities];

      let found = selectedAbilities.find(elm => elm.id === ability.id);
      
      if (found){
        get().actions.setOpenConfAlert(true);
        
      }else{
        selectedAbilities.push(ability);
      }

      set((state) => ({ selectedAbilities: selectedAbilities }));
    },
    refreshSelectedAbilities: ()=>{
      const selectedAbilitiesIds = Array.from(new Set(
        [ ...get().courseSkills.map(elm => elm.skill.ability_id), 
          ...get().courseAttitudes.map(elm => elm.attitude.ability_id)]));


      const abilities = get().abilities;

      const selectedAbilities = abilities.filter(ability => {
        return (selectedAbilitiesIds.includes(ability.id))
      });

      set(produce((state) => { state.selectedAbilities= selectedAbilities}));
    },

    createAbilityElement(values){
      if (values.name.trim() === '' || values.description.trim() === '') return;

      const api = new Api();
      let selectedAbilities = [...get().selectedAbilities];
      let currentSelectedAbility = selectedAbilities[0];
      
      let params = {};
      params[get().fillingAbilitiesStep] = values;
      params.abilityId = currentSelectedAbility.id;
      ((get().fillingAbilitiesStep === AbilityFillingStepEnum.STEP_1)? api.addSkill(params) : api.addAttitude(params))
        .then((response) => {
          selectedAbilities[0] = response.data.ability;
          get().actions.toggleFillingNewElm();
          
          get().actions.setSnackMessage({show: true, type:"SUCCESS",text: i18n.t(get().fillingAbilitiesStep+"_saved")})

          set((state) => ({ selectedAbilities: selectedAbilities }));

        })
      .catch((err) => console.log(err));
    }

  }

  

}))
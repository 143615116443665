import React, {useEffect, useRef, useState} from "react"
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import { useDebouncedCallback } from "use-debounce";

import {AbilityFillingStepEnum} from "app/components/shared/enums/global-enums.tsx"

import useStore from 'app/store/store'
import './ElmTick.scss'


export default function ElmTick({elm, typeFor}){

  const courseSkills = useStore((state) => state.courseSkills);
  const courseAttitudes = useStore((state) => state.courseAttitudes);
  const fillingAbilitiesStep = useStore((state) => state.fillingAbilitiesStep);
  
  const nameTxt = useRef();
  const nameTxtBis = useRef();

  const descTxt = useRef();
  const descTxtBis = useRef();

  let courseElms;
  if (typeFor === AbilityFillingStepEnum.STEP_1){
    courseElms = [...courseSkills];
  }
  if (typeFor === AbilityFillingStepEnum.STEP_2){
    courseElms = [...courseAttitudes];
  }

  const storeActions = useStore((state) => state.actions);

  const skillFound = courseElms.find(courseElm => {
               return (courseElm[typeFor].id === elm.id);
            });


  let [clsMarquee, setClsMarquee] = useState("");
  let [clsMarqueeBis, setClsMarqueeBis] = useState("");

  function refreshMarqueeEffect() {
    if (nameTxtBis.current && nameTxt.current && (nameTxtBis.current.offsetWidth > nameTxt.current.offsetWidth+3)){
      setClsMarquee(" one-line ");
    } else{
      setClsMarquee(" ");
    }
  }

  const handleResize = useDebouncedCallback(
    refreshMarqueeEffect,
    500
  );

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return _ => {
      window.removeEventListener('resize', handleResize);
    }
  }, [elm.name, handleResize, fillingAbilitiesStep]);


  useEffect(() => {
    if (descTxtBis.current && descTxt.current && (descTxtBis.current.offsetWidth > descTxt.current.offsetWidth+3)){
      setClsMarqueeBis(" one-line ");
    } else{
      setClsMarqueeBis(" ");
    }
  }, [elm.description]);



  return (
    <>
      <Box >
        <div className="v-space-1"></div>
        <Grid container >

          <Grid item xs={2} className="d-f jc-c ai-t" sx={{marginTop: '7px'}}>
            <Checkbox className={typeFor ===  AbilityFillingStepEnum.STEP_1? "ch-third" : "ch-fifth"} checked={!!skillFound} sx={{p:0}}  onClick={() => storeActions.toggleElement(elm, null, null)}    />
          </Grid>
          <Grid item xs className="d-f turncate elm-tick " sx={{flexDirection:'column', paddingTop: '8px'}}>
            {
            <div ref={nameTxt}  className={clsMarquee}>
              <Typography variant="span" sx={{fontSize: 14}} className={"  font-2 " + (typeFor === AbilityFillingStepEnum.STEP_1? "third-color" : "fifth-color")}>
                {elm.name}
              </Typography>
              
            </div>
            }
            
          </Grid>
        </Grid>
        <div className="v-space-1"></div>
      </Box>
      <span ref={nameTxtBis} attr-ko={clsMarqueeBis} className="font-2 hiddenBisTxt" >{elm.name}</span>
      <span ref={descTxtBis} className="font-2 hiddenBisTxt" >{elm.description}</span>
      </>
    );

}
import Api from 'app/helpers/api'

export const academicYearsSlice = ((set, get) => ({
  academicYears: [],

  actions: {
    fetchAcademicYears: async (params) => {
      const api = new Api();
      api.getAcademicYears()
        .then((response) => {
          set((state) => ({ academicYears: response.data.academic_years }));
        })
      .catch((err) => console.log(err));
    },

  }

}))
import React from "react"
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import AppCircle from 'app/components/shared/AppCircle/AppCircle'

import {useTranslation} from "react-i18next"
import useStore from 'app/store/store'
import {AbilityFillingStepEnum} from "app/components/shared/enums/global-enums.tsx"
import './InstructorActions.scss'


export default function InstructorActions ({anchorEl, open, handlePopoverClose, onMouseEnterFunc, elm, knowledge, targetKey, refBox}){

  const {t} = useTranslation();

  const instructorActions = useStore((state) => state.instructorActions);
  const fillingAbilitiesStep = useStore((state) => state.fillingAbilitiesStep);

  const courseSkills = useStore((state) => state.courseSkills);
  const courseAttitudes = useStore((state) => state.courseAttitudes);
  
  const storeActions = useStore((state) => state.actions);

  let color, backgroundColor, courseElems;

  if (fillingAbilitiesStep === AbilityFillingStepEnum.STEP_1){
    color = "third-color";
    backgroundColor = "third-color-faded-2";
    courseElems = courseSkills;

  }
  if (fillingAbilitiesStep === AbilityFillingStepEnum.STEP_2) {
    color = "fifth-color";
    backgroundColor = "fifth-color-faded-2";
    courseElems = courseAttitudes;
  }

  const usedActions = courseElems.filter(element => element[fillingAbilitiesStep].id === elm.id && element.knowledge?.id === knowledge.id).map(element => element.instructor_action?.id); 

  return (

      <Popover
        sx={{
          pointerEvents: 'none',
        }}
        open={!!open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{onMouseLeave: handlePopoverClose}}
        data-key={targetKey}
        container={refBox.current}
        disableRestoreFocus={true}
      >
        
        <Box  className="i-c-container " >

          <Grid container item  xs={12}  >
            <Grid item xs={8} >
            </Grid>
            <Grid  item xs className="d-f ai-c jc-c">
              <Typography variant="span" className="white-color font-1 s-text">
              {
                (fillingAbilitiesStep === AbilityFillingStepEnum.STEP_1) 
                  ? t("Skill")
                  : t("Attitude")
              }
              </Typography>
            </Grid>
          </Grid>

          {
            instructorActions.map((instructorAction, key)=>{
              return (
                <Grid container item  xs={12} key={key} >
                  <div className="v-space-2"></div>
                  <Grid item xs={8} sx={{paddingRight:1.5}} className="d-f ai-c jc-c">
                    <Typography variant="span" className="white-color font-1 s-text">
                    {t(instructorAction.name)}
                    </Typography>
                  </Grid>
                  <Grid  item xs className="d-f ai-c jc-c">
                    
                    <AppCircle 
                      onClickFunc={() => {
                        if (fillingAbilitiesStep === AbilityFillingStepEnum.STEP_1)
                        storeActions.toggleElement(elm, knowledge, instructorAction)
                        if (fillingAbilitiesStep === AbilityFillingStepEnum.STEP_2)
                        storeActions.toggleElement(elm, knowledge, instructorAction)
                      }} 
                      content={
                        <Typography variant="span"  className={"text-circle-tick "+color}
                        >
                          {t(instructorAction.code)}
                        </Typography>
                      }
                      size={25}
                      background={
                      
                        usedActions.includes(instructorAction.id)
                        ? "main-color-faded-2"
                        : backgroundColor

                      }
                       />
                  </Grid>
                </Grid>
                );
            })
          }
          
        </Box>
    
      </Popover>
    );
}
import React from "react"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import SimpleBar from "simplebar-react"


import 'simplebar-react/dist/simplebar.min.css';
import './CoursesLegend.scss';

export default function CoursesLegend({filteredCourses}){

  let fAS = "";

  // console.log(filteredCourses);


  return (
    <Box id="js-sel-ab-container" className={"sel-cl-container "}>
      <SimpleBar  className="h-100 " >
      {
        filteredCourses.map((fc, key)=>{
          return (
            <Box key={key}>
              <Typography variant="span" sx={{fontSize: 14, fontWeight: 700, color: fc.color}} className={"selected-item font-4 js-ab-"+fAS}>
                - ({fc.id}) {fc.name}
              </Typography>
              <div className="v-space-2"></div>
            </Box>
            )
        })
      }
      </SimpleBar>
    </Box>
    )
}
import Api from 'app/helpers/api'

export const disciplinesSlice = ((set, get) => ({
  disciplines: [],

  discipline: {},

  actions: {
    fetchDisciplines: async (params) => {
      const api = new Api();
      api.getDisciplines()
        .then((response) => {
          set((state) => ({ disciplines: response.data.disciplines }));
        })
      .catch((err) => console.log(err));
    },

  }

}))
import React from "react"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import AppButton from 'app/components/shared/AppButton/AppButton.tsx'
import {ButtonTypeEnum, ButtonSizeEnum, ButtonColorEnum} from "app/components/shared/enums/global-enums.tsx"
import useStore from 'app/store/store'


export default function ConfirmationAlert({title, content, mainAction, mainActionText, secondaryActionText}){

  const storeActions = useStore((state) => state.actions);

  return (
      <Dialog
        open={true}
        onClose={()=>storeActions.setOpenConfAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <AppButton
            buttonLabel={secondaryActionText}
            buttonType={ButtonTypeEnum.TEXT}
            buttonSize={ButtonSizeEnum.MEDIUM}
            buttonColor={ButtonColorEnum.FIFTH}
            onClickFunc={()=>storeActions.setOpenConfAlert(false)}
          />
          <AppButton
            buttonLabel={mainActionText}
            buttonType={ButtonTypeEnum.TEXT_OUTLINE}
            buttonSize={ButtonSizeEnum.MEDIUM}

            buttonColor={ButtonColorEnum.FIFTH}
            onClickFunc={
              ()=>{
                mainAction();
                storeActions.setOpenConfAlert(false);
              }
            }
          />
        </DialogActions>
      </Dialog>
    );

}
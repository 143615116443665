import React, {useRef, useEffect, useState} from "react";
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import {useParams} from 'react-router-dom';
import { PDFViewer } from '@react-pdf/renderer';
import {useTranslation} from "react-i18next"
import CircularProgress from '@mui/material/CircularProgress';
import ElmsPageReport from "app/components/Courses/CourseReport/ElmsPageReport/ElmsPageReport";
import CourseVisualisation from "app/components/Courses/CourseVisualisation/CourseVisualisation";
import {AbilityFillingStepEnum} from "app/components/shared/enums/global-enums.tsx"
import cssVars from 'app/helpers/_variables.module.scss';
import useStore from 'app/store/store';

Font.register({ family: 'Bebas', src: '../../../BebasNeue-Regular.ttf' });
Font.register({ family: 'Tinos-bold', src: '../../../Tinos-Bold.ttf' });
Font.register({ family: 'Tinos-reg', src: '../../../Tinos-Regular.ttf' });
Font.register({ family: 'Nato-reg', src: '../../../NotoSansJP-Regular.ttf' });
Font.register({ family: 'Nato-bold', src: '../../../NotoSansJP-ExtraBold.ttf' });

export default function CourseReport(){

  const {t} = useTranslation();

  let { courseId } = useParams();

  const stageRef = useRef();
  const stageRef2 = useRef();

  const course = useStore((state) => state.course);
  const selectedAbilities = useStore((state) => state.selectedAbilities);
  const knowledges = useStore((state) => state.knowledges);
  const skillImageData = useStore((state) => state.skillImageData);
  const attitudeImageData = useStore((state) => state.attitudeImageData);
  const storeActions = useStore((state) => state.actions);

  const styles = StyleSheet.create({
    page: {
      marginTop: 30,
      marginLeft: 30,
      marginRight: 30,
      flexDirection: 'column',
    },
    cWidth: {
      width: '90%'
    },

    defContainer: {
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
      paddingBottom: 8,
      borderLeft: '1 solid '+cssVars['second-color-faded-4'],
      borderRight: '1 solid '+cssVars['second-color-faded-4']
    },

    delimiter: {
      height: "1",
      backgroundColor: cssVars['second-color-faded-4']
    },

    hDelimiter: {
      height: "100%",
      width: "1",
      backgroundColor: cssVars['second-color-faded-4']
    },

    smTitle:{
      fontFamily: 'Bebas',
      fontSize: 12, 
      fontWeight: "bold"
    },
    nmTitle:{
      fontFamily: 'Tinos-reg',
      fontSize: 10
    },
    footerText: {
      textAlign: 'center',
      position: 'absolute',
      fontSize: 10,
      bottom: 40,
      left: 0,
      right: 0,
    }
  });


  const courseSkills = [...useStore((state) => state.courseSkills)];
  const courseAttitudes = [...useStore((state) => state.courseAttitudes)];
  const courseSkillApps = [...useStore((state) => state.courseSkillApplications)];
  const courseAttitudeApps = [...useStore((state) => state.courseAttitudeApplications)];

  const unqCourseSkills = [...new Map(courseSkills.map(cS => [cS.skill.id, cS.skill])).values()];
  const unqCourseAttitudes = [...new Map(courseAttitudes.map(cS => [cS.attitude.id, cS.attitude])).values()];


  let cptSelAb = 0;

  useEffect(() => {
    storeActions.fetchAbilities();
    storeActions.fetchCourse({courseId: courseId});
    storeActions.fetchCourseSkills({courseId: courseId});
    storeActions.fetchCourseAttitudes({courseId: courseId});
    storeActions.fetchKnowledges();
    storeActions.fetchInstructorActions();

  }, [courseId, storeActions]);

  if (course.id && selectedAbilities.length > 0 && (courseSkills.length>0 || courseAttitudes.length > 0)){

    let myInterval = setInterval(()=>{
      if (stageRef.current && stageRef2.current){
        clearInterval(myInterval);
        storeActions.setSkillImageData(stageRef.current.toDataURL({pixelRatio: 2}));
        storeActions.setAttitudeImageData(stageRef2.current.toDataURL({pixelRatio: 2}));
      }
    }, 2000);

  }

  let [docLoaded, setDocLoaded] = useState(false);

  function onDocLoad(){
    setDocLoaded(true);
  }

  return (
    <>
    
    {
      (skillImageData === null) && 
      <>
        <CourseVisualisation typeFor={AbilityFillingStepEnum.STEP_1}  stageRef={stageRef} fxWidth={1050} fxHeight={700} />
      </>
    } 
    {
      (attitudeImageData === null) && 
      <>
        <CourseVisualisation typeFor={AbilityFillingStepEnum.STEP_2} stageRef={stageRef2} fxWidth={1050} fxHeight={700} />
        
      </>
    }

    {
      (docLoaded===false) &&
    <div className="hide-bloc d-f fd-c ai-c jc-c">
      <CircularProgress/>
      <div className="v-space-3" ></div>
      <div>{t("Creating PDF...")}</div>
    </div>
    }

    {
    (skillImageData !== null) && (attitudeImageData !== null) &&

    <PDFViewer  style={{height: '100%'}} onLoad={onDocLoad}>
      <Document >
        <Page style={styles.page}>
          
          <View style={{marginBottom:10,...styles.cWidth}}>
            <Text style={{fontFamily: 'Bebas', fontSize: 14, textAlign: 'center'}} >{t("Course Report")}</Text>
          </View>
          <View style={{...styles.cWidth, ...styles.delimiter}}></View>
          <View style={{...styles.defContainer, ...styles.cWidth}}>
            <Text style={{...styles.smTitle}} >
              {t("Course Name")}
              {": "}
              <Text style={{...styles.nmTitle}} >
                {course.name}
              </Text>
            </Text>
            
          </View>
          <View style={{...styles.cWidth, ...styles.delimiter}}></View>
          <View style={{...styles.defContainer, ...styles.cWidth}}>
            <Text style={{...styles.smTitle}} >
              {t("Course Instructor")}
              {": "}
              <Text style={{...styles.nmTitle}} >
                {"Shravya"}
              </Text>
            </Text>
          </View>
          <View style={{...styles.cWidth, ...styles.delimiter}}></View>
          <View style={{...styles.defContainer, ...styles.cWidth}}>
            <Text style={{...styles.smTitle}} >
              {t("Course Description")}
              {": "}
            </Text>
            <Text style={{...styles.nmTitle}} >
              {course.description}
            </Text>
            
          </View>
          <View style={{...styles.cWidth, ...styles.delimiter}}></View>
          <View style={{...styles.defContainer, ...styles.cWidth}}>
            <Text style={{...styles.smTitle}} >
              {t("Learning Outcomes")}
              {": "}
            </Text>
            <Text style={{...styles.nmTitle}} >
              {course.learning_outcomes}
            </Text>
            
          </View>
          <View style={{...styles.cWidth, ...styles.delimiter}}></View>
          <View style={{...styles.defContainer, ...styles.cWidth}}>
            <Text style={{...styles.smTitle}} >
              {t("Measurable Outcomes")}
              {": "}
            </Text>
            <Text style={{...styles.nmTitle}} >
              {course.measureable_outcomes}
            </Text>
            
          </View>
          <View style={{...styles.cWidth, ...styles.delimiter}}></View>
          <View >
            <View  style={{...styles.defContainer, ...styles.cWidth}}>
              <Text style={{...styles.smTitle}} >
                {t("Abilities (AB)")}
                {": "}
              </Text>
              {
                selectedAbilities.map((ability, key)=>{
                  cptSelAb++;
                  return (
                    <Text key={key} style={{...styles.nmTitle}} >
                      {cptSelAb} {'. '} {ability.name}
                    </Text>
                  )
                })
              }
            </View>
          </View>
          <View style={{...styles.cWidth, ...styles.delimiter}}></View>
          <View style={{...styles.defContainer, ...styles.cWidth}}>
            <Text style={{fontSize: 10, fontFamily: "Tinos-bold"}} >
              {t("fostering_an_ability")}
            </Text>
          </View>
          <View style={{...styles.cWidth, ...styles.delimiter}}></View>
          <Text fixed style={{...styles.footerText,...styles.cWidth}}>
            {t("Generated by thewhitespaces.co")}
          </Text>
        </Page>

        {
          (skillImageData !== null) &&
          <ElmsPageReport  image={skillImageData} selectedAbilities={selectedAbilities} knowledges={knowledges} unqCourseElms={unqCourseSkills} courseElms={courseSkills} courseElmApps={courseSkillApps} typeFor="skill" />
        }
        {
          (attitudeImageData !== null) &&
          <ElmsPageReport  image={attitudeImageData} selectedAbilities={selectedAbilities} knowledges={knowledges} unqCourseElms={unqCourseAttitudes} courseElms={courseAttitudes} courseElmApps={courseAttitudeApps} typeFor="attitude" />
        }
        
      </Document>
    </PDFViewer>
  }
    </>
  )
}
import React from "react"

import {Text, Path, Circle, Line, Group } from 'react-konva';
import useStore from 'app/store/store'
import cssVars from 'app/helpers/_variables.module.scss';

export default function DynamicPartial ({selectedAbilities, unqCourseElms, knowledges, courseElms, courseElmApps,  mainColor1, center, kngsCir, kngsCols, aiColors, fAS }){

  const visualisation = useStore((state) => state.visualisation);
  const kngsRads = [7, 8, 10];
  let gapAngle = 360/(unqCourseElms.length+selectedAbilities.length);
  let cptLine = -10, cptElm = 0, cptAbility=0;
  const noOptEnabled = (!visualisation.opE && !visualisation.opI && !visualisation.opT && !visualisation.opA); 

  /*this define the starting angle*/
  function angleToDestPoints(x1, y1, length, angle) {
      angle *= Math.PI / 180;
      var x2 = x1 + length * Math.cos(angle), y2 = y1 + length * Math.sin(angle);
      return [x2, y2];
  }

  function handleTextMouseEnter(event, elmId, forAbility=false) {
    const container = event.target.getStage().container();
    container.style.cursor = "pointer";
    let thisElm;
    if (forAbility){
      thisElm = document.getElementsByClassName("js-ab-"+fAS+"-"+elmId)[0];
    }else{
      thisElm = document.getElementsByClassName("js-elm-"+fAS+"-"+elmId)[0];
    }
    thisElm.classList.add("hover");
    let scroller = document.querySelectorAll("#js-sel-ab-container .simplebar-content-wrapper")[0];
    scroller.scrollTop = thisElm.offsetTop;
  }

  function handleTextMouseLeave(event, elmId, forAbility=false) {
    const container = event.target.getStage().container();
    container.style.cursor = "default";
    let thisElm;
    if (forAbility){
      thisElm = document.getElementsByClassName("js-ab-"+fAS+"-"+elmId)[0];
    }else{
      thisElm = document.getElementsByClassName("js-elm-"+fAS+"-"+elmId)[0];
    }
    thisElm.classList.remove("hover");
  }

  function handleCircleMouseEnter(event, elmId) {
    handleTextMouseEnter(event, elmId);
    let circle = event.target;
    circle.radius(circle.attrs.radius+5);
  }

  function handleCircleMouseLeave(event, elmId) {
    handleTextMouseLeave(event, elmId);
    let circle = event.target;
    circle.radius(circle.attrs.radius-5);
  }

  function handleGroupMouseEnter(event, elmId, key) {
    handleTextMouseEnter(event, elmId);
    let elms = event.target.getStage().find('#js-path-'+elmId+'-'+key);
    let size = kngsRads[key]+5;
    let arc = 'M 0, 0 m -'+size+', 0 a '+size+','+size+' 0 1,1 '+size*2+',0';
    elms[0].data(arc); 
    if (elms.length > 1) {
      arc = 'M 0, 0 m '+size+', 0 a '+size+','+size+' 0 1,1 -'+size*2+',0';
      elms[1].data(arc); 
    }
  }


  function handleGroupMouseLeave(event, elmId, key) {
    handleTextMouseLeave(event, elmId);
    let elms = event.target.getStage().find('#js-path-'+elmId+'-'+key);
    let size = kngsRads[key];
    let arc = 'M 0, 0 m -'+size+', 0 a '+size+','+size+' 0 1,1 '+size*2+',0';
    elms[0].data(arc); 
    if (elms.length > 1) {
      arc = 'M 0, 0 m '+size+', 0 a '+size+','+size+' 0 1,1 -'+size*2+',0';
      elms[1].data(arc); 
    }

  }
  

  return(
  <>
  {
  selectedAbilities.map((ability, key1) => {
    cptLine++;  cptAbility++;
    const nbElms = unqCourseElms.filter((elm)=> elm.ability_id === ability.id).length;
    let startingAngle = gapAngle*cptLine;
    let titleAngle = startingAngle+(gapAngle*((nbElms+1)/2));

    const pnsTitleElm = angleToDestPoints(center[0], center[1] , kngsCir[2]+65, (titleAngle));

    return (
      <Group key={key1}>
        <Text text={cptAbility} fill={mainColor1} align="center" verticalAlign="middle" x={pnsTitleElm[0]} y={pnsTitleElm[1]} fontSize={24} fontStyle="bold" fontFamily={cssVars["font-1"]} padding={0} lineHeight={0} width={20} height={20} offsetX={10}  offsetY={10}  onMouseEnter={(event)=>handleTextMouseEnter(event, ability.id, true)} onMouseLeave={(event)=>handleTextMouseLeave(event, ability.id, true)}  />



        <Line
          x={center[0]} y={center[1]} stroke={mainColor1} strokeWidth={1.0}
          dash={[7, 5]} points={[0, 0, ...angleToDestPoints(0, 0, kngsCir[2]+70, (gapAngle*cptLine))]}
                />
          {
          unqCourseElms.filter((elm)=> elm.ability_id === ability.id).map((elm, key2) => {
          cptLine++; cptElm++;
          const applied = courseElmApps.find((cApp)=> cApp[fAS+"_id"] === elm.id && cApp.applied === true);
          const lineAngle = gapAngle*cptLine;
          const pnsTxtElm = angleToDestPoints(center[0], center[1] , kngsCir[2]+25, (lineAngle));

          return (
            <Group key={key2}>
              <Text text={(fAS[0]+ " "+ cptElm).toUpperCase()} fill="black" x={pnsTxtElm[0]} y={pnsTxtElm[1]} fontSize={14} fontFamily={cssVars["font-1"]} padding={0} lineHeight={0}  width={20} height={20} offsetX={10}  offsetY={10} align="center" verticalAlign="middle" onMouseEnter={(event)=>handleTextMouseEnter(event, elm.id)} onMouseLeave={(event)=>handleTextMouseLeave(event, elm.id)} />

              <Line
                x={center[0]} y={center[1]} stroke={ (visualisation.applied && applied) ? "black": "#ccc"}
                strokeWidth={0.3} points={[0, 0, ...angleToDestPoints(0, 0, kngsCir[2], (lineAngle))]}
                      />
                {
                  knowledges.map((knowledge, key3) => {
                    const filteredElms = courseElms.filter((courseElm)=> courseElm[fAS].id === elm.id && courseElm.knowledge?.id === knowledge.id);
                    const cirLocation = angleToDestPoints(center[0], center[1], kngsCir[key3], (lineAngle));

                    return (
                      <Group key={key3} >

                        {
                          filteredElms.map((elm, key4)=>{
                            let IA = elm.instructor_action;
                            let arc = 'M 0, 0 m -'+kngsRads[key3]+', 0 a '+kngsRads[key3]+','+kngsRads[key3]+' 0 1,1 '+kngsRads[key3]*2+',0';
                            if (key4 === 1) arc = 'M 0, 0 m '+kngsRads[key3]+', 0 a '+kngsRads[key3]+','+kngsRads[key3]+' 0 1,1 -'+kngsRads[key3]*2+',0';

                            let cColor = noOptEnabled? kngsCols[key3] : aiColors["color"+IA.code];
                            
                            return (
                              <Group key={key4} >
                                {
                                (visualisation["op"+IA.code] || noOptEnabled)  && 
                                <>
                                  {(!visualisation["op"+filteredElms[0].instructor_action.code] || !visualisation["op"+filteredElms[1]?.instructor_action.code]) &&
                                  <>
                                    <Circle x={cirLocation[0]} y={cirLocation[1]} radius={kngsRads[key3]}  fill={cColor} 
                                      onMouseEnter={(event)=>handleCircleMouseEnter(event, elm[fAS].id)} onMouseLeave={(event)=>handleCircleMouseLeave(event, elm[fAS].id)}

                                      />                                    
                                  </>
                                  }

                                  {visualisation["op"+filteredElms[0].instructor_action.code] && visualisation["op"+filteredElms[1]?.instructor_action.code] &&
                                  <Path 
                                    onMouseEnter={(event)=>handleGroupMouseEnter(event, elm[fAS].id, key3)}
                                    onMouseLeave={(event)=>handleGroupMouseLeave(event, elm[fAS].id, key3)}

                                    id={"js-path-"+elm[fAS].id+"-"+key3}
                                    rotation={lineAngle}
                                    x={cirLocation[0]} y={cirLocation[1]}
                                    data={arc}
                                    fill={cColor}

                                  />}
                                </>
                                }
                              </Group>
                              )
                          })
                        }

                      </Group>
                      )
                  })
                }      
              </Group>

            )
            })
          }
      </Group>
      )
    })
  }
  </>
)}
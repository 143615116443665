import React from "react";
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import {useNavigate} from 'react-router-dom';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { useTranslation } from 'react-i18next';

import SimpleBar from "simplebar-react"
import {AppRoutes} from "app/helpers/routes";

import 'simplebar-react/dist/simplebar.min.css';
import "./CourseCover.scss"


export default function CourseCover({course}) {
  const navigate = useNavigate();
  const {t} = useTranslation();

  function editCourseClicked(){
    navigate(AppRoutes.editCoursePath.replace(":courseId", course.id));
  }

  return (
  	<Box className="cover w-100" sx={{ borderRadius: 6 }}>
	    <Grid className="w-100 h-100" container spacing={2} sx={{p: 2}}>
	    	<Grid item xs={4.7} className="h-100"> 
	    		<Typography variant="span" className="title main-color font-1">
	    			{t('Description')}
	    		</Typography>
		    	<div className="v-space-1"></div>
          <SimpleBar  className="h-95">
  		    	<Typography variant="span" className="text font-2 second-color">
  		    		{course.description}
  	    		</Typography>
          </SimpleBar>
		    </Grid>
	    	<Grid container item xs={6.3}> 
	    		<Grid item xs={12} >
	    			<Typography variant="span" className="title main-color font-1">
	    				{t('Learning Outcomes')}
	    			</Typography>
	    			<div className="v-space-1"></div>
            <SimpleBar  className="custom-hight">
  	    			<Typography variant="span" className="text font-2 second-color">
  	    				{course.learning_outcomes}
  	    			</Typography>
            </SimpleBar>
	    		</Grid>
	    		<div className="v-space-3"></div>
	    		<Grid item xs={12}>
	    			<Typography variant="span" className="title main-color font-1">
	    				{t('Measureable Outcomes')}
	    			</Typography>
	    			<div className="v-space-1"></div>
            <SimpleBar  className="custom-hight">
  	    			<Typography variant="span" className="text font-2 second-color">
  	    				{course.measureable_outcomes}
  	    			</Typography>
            </SimpleBar>
	    		</Grid>
	    	</Grid>
        <Grid className="jc-r" container item xs={1}>
          <Box >
            <div className="edit" onClick={editCourseClicked}>
              <ModeEditOutlinedIcon />
            </div>
          </Box>
        </Grid>
	    </Grid>
    </Box>
  );
}

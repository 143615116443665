import React from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

import AppCircle from "app/components/shared/AppCircle/AppCircle"
import './CircleInfo.scss'

export default function CircleInfo({numbering, text, mainColor="fourth-color", hPadding = 0}){

  return (

    <Grid container >
      <Grid className="d-f jc-c" item xs={12}>
        <AppCircle 
          content={
            <Typography variant="span" sx={{fontSize: 12}}>
              {numbering}
            </Typography>
          } 
          size={17}
          background={mainColor} />
      </Grid>
      <Grid item xs={12} className="d-f jc-c">
        <Typography variant="span" sx={{paddingLeft: hPadding, paddingRight: hPadding}} className={"info-subtitle center-text " + mainColor}>
          {text}
        </Typography>
      </Grid>
    </Grid>

    );


}
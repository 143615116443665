import Api from 'app/helpers/api'

export const academicTermsSlice = ((set, get) => ({
  academicTerms: [],

  actions: {
    fetchAcademicTerms: async (params) => {
      const api = new Api();
      api.getAcademicTerms()
        .then((response) => {
          set((state) => ({ academicTerms: response.data.academic_terms }));
        })
      .catch((err) => console.log(err));
    },

  }

}))
export const enum SaveChangesEnum {
  COURSE_FORM = 0,
  COURSE_ABILITIES = 1
}

export const enum ButtonTypeEnum {
  OUTLINED = "outlined",
  TEXT = "text",
  TEXT_OUTLINE = "text-outline",
  CONTAINED = "contained",
  PRIMARY = "primary",
  SECONDARY = "secondary",
  REGULAR = "regular",
  TEXT_ACTIVE = "text-active",
  DEFAULT = "default"
}

export const enum ButtonSizeEnum {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export const enum ButtonColorEnum {
  MAIN_FADED = "main-faded",
  FIFTH = "fifth",
  FIFTH_FADED = "fifth-faded",
  FOURTH = "fourth-faded",
  SIXTH_FADED = "sixth-faded"
}

export const enum AbilityFillingStepEnum {
  STEP_1 = "skill",
  STEP_2 = "attitude"
}

export const enum DataRecordType {
  COURSE = "course",
  ACCREDITATION = "accreditation"
}


export const enum CollaborateViewEnum {
  VIEW_1 = "overview",
  VIEW_2 = "program",
  // VIEW_3 = "university",
  VIEW_4 = "customize"
}
import React, {useEffect} from "react";
import { useDebouncedCallback } from "use-debounce";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {useParams, useSearchParams} from 'react-router-dom';

import {useTranslation} from "react-i18next"

import Footer from "app/components/shared/Footer/Footer";
import Navbar from "app/components/shared/Navbar/Navbar.tsx";
import AbilitiesSideBar from "app/components/Abilities/AbilitiesSideBar/AbilitiesSideBar";
import CourseHeader from "app/components/Courses/CourseHeader/CourseHeader";
import CourseCover from "app/components/Courses/CourseCover/CourseCover";
import CourseVisualisation from "app/components/Courses/CourseVisualisation/CourseVisualisation";
import AbilityBox from "app/components/Abilities/AbilityBox/AbilityBox";
import AbilityDetails from "app/components/Abilities/AbilityDetails/AbilityDetails";
import ConfirmationAlert from "app/components/shared/ConfirmationAlert/ConfirmationAlert";
import CourseFooter from "app/components/Courses/CourseFooter/CourseFooter";

import {SaveChangesEnum} from "app/components/shared/enums/global-enums.tsx";
import useStore from 'app/store/store'
import useChangesWatcherStore from 'app/store/changesWatcherStore'
import './AbilitiesCover.scss';

export default function AbilitiesCover() {

  const {t} = useTranslation();
  let [searchParams,] = useSearchParams()

  let { courseId } = useParams();
  let visualisation =searchParams.get("visualisation");
  let abilityDetailesOpen =searchParams.get("details");

  const course = useStore((state) => state.course);
  const selectedAbilities = useStore((state) => state.selectedAbilities);
  const openConfAlert = useStore((state) => state.openConfAlert);

  const storeActions = useStore((state) => state.actions);

  useEffect(() => {
    storeActions.fetchCourse({courseId: courseId});
    storeActions.fetchCourseSkills({courseId: courseId});
    storeActions.fetchCourseAttitudes({courseId: courseId});
    storeActions.fetchKnowledges();
    storeActions.fetchInstructorActions();
    
  }, [courseId, storeActions]);
  
  const saveChanges = useDebouncedCallback(
      // function
      async () => {
        await storeActions.updateCourseAbilities();
      },
      // delay in ms
      3000
    );

  useEffect(() => {
    useChangesWatcherStore.subscribe(state => {
        if (!!state.unsavedChanges){
          storeActions.setUnsavedChanges(false);

          storeActions.setSnackMessage({show: true, type:"INFO",text: t("Saving"), isPendingMessage: true});
          saveChanges.cancel();
          saveChanges();
        }
      }
    )
  }, [saveChanges, storeActions, t]);


  return (
  	<>
  		<Navbar/>
	    <Container  maxWidth="xl">
	      <CourseHeader course={course}  visualisationButton={true} />
	      <Grid alignItems="stretch" container  spacing={1} >
          <Grid item className="sidebar-container"  >
            <AbilitiesSideBar 
              course={course}
                  />
          </Grid>
          <Grid  container item md >
            <Stack className="w-100" spacing={1} md={12} >
              <CourseCover course={course}/>
           
              <Box className="ability-details-container w-100 d-f">
                {
                  visualisation && 
                  <CourseVisualisation />

                }
                {!visualisation && selectedAbilities.length > 0
                  ? (
                    !abilityDetailesOpen 
                    ? <AbilityBox ability={selectedAbilities[0]} />
                    : 
                      <AbilityDetails ability={selectedAbilities[0]}/>  
                  )
                  : null
                }
              </Box>
            </Stack>
          </Grid>
	      </Grid>
        <div className="v-space-2"></div>
        <CourseFooter course={course} saveChangeType={SaveChangesEnum.COURSE_ABILITIES}/>
        <div className="v-space-10"></div>
	    </Container>
	    <Footer/>

      {
        openConfAlert &&  <ConfirmationAlert 
          title={t("Confirmation")}
          content={t("if_uncheck_ability")}
          mainAction={()=>storeActions.removeCourseAbility()}
          mainActionText={t("Continue")}
          secondaryActionText={t("Cancel")}
        />
      }
    </>
  );
}
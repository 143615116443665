import React from "react";
import { Card, Typography } from "@mui/material";
import phoneIcon from "../../../assets/images/PhoneCall.svg";
import envelopeIcon from "../../../assets/images/EnvelopeSimple.svg";
import "./Contact.scss";
import "../../../../../src/App.scss";

export default function Contact() {
  return (
    <Card className="get-in-touch-container">
      <Typography variant="h4" className={"card-title"}>
        Let's get in touch!
      </Typography>
      <Typography variant="body1" className="subtitle">
        Got questions about DesCA? Our team is here to help. Contact us for a
        quick and friendly support.
      </Typography>
      <Typography className="d-f ai-c contact-phone">
        <img src={envelopeIcon} alt="phone icon" />
        <a className="ml-1 c-b link" href="tel:+65 86431336">
          +65 86431336
        </a>
      </Typography>
      <Typography className="d-f ai-c">
        <img src={phoneIcon} alt="email icon" />
        <a className="ml-1 c-b link" href="mailto:Hello@thewhitespaces.co">
          Hello@thewhitespaces.co
        </a>
      </Typography>
    </Card>
  );
}

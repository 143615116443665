import React, {useState} from "react"
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import CheckIcon from '@mui/icons-material/Check';

import {useTranslation} from 'react-i18next'

import AppCircle from "app/components/shared/AppCircle/AppCircle"
import InstructorActions from "app/components/Knowledges/InstructorActions/InstructorActions"

import {AbilityFillingStepEnum} from "app/components/shared/enums/global-enums.tsx"

import useStore from 'app/store/store'
import './KnowledgeTick.scss'


export default function KnowledgeTick({elm, refBox, typeFor}){

  const {t} = useTranslation();

  // const fillingAbilitiesStep = useStore((state) => state.fillingAbilitiesStep);
  const fillingAbilitiesStep = typeFor;
  
  const courseSkills = useStore((state) => state.courseSkills);
  const courseAttitudes = useStore((state) => state.courseAttitudes);

  const courseSkillApplications = useStore((state) => state.courseSkillApplications);

  const courseAttitudeApplications = useStore((state) => state.courseAttitudeApplications);

  let courseElems;
  let courseElemApplications;

  let color, backgroundColor;

  if (fillingAbilitiesStep === AbilityFillingStepEnum.STEP_1){
    courseElems = courseSkills;
    courseElemApplications = courseSkillApplications;
    color = "third-color";
    backgroundColor = "third-color-faded-2";
  }
  if (fillingAbilitiesStep === AbilityFillingStepEnum.STEP_2) {
    courseElems = courseAttitudes;
    courseElemApplications = courseAttitudeApplications;
    color = "fifth-color";
    backgroundColor = "fifth-color-faded-2";
  }


  const knowledges = useStore((state) => state.knowledges);
  const storeActions = useStore((state) => state.actions);

  const [anchorEls, setAnchorEls] = useState(Array(knowledges.length).fill(null));
  const [opens, setOpens] = useState(Array(knowledges.length).fill(false));

  const uncompleteSkills = useStore((state) => state.uncompleteSkills);
  const uncompleteAttitudes = useStore((state) => state.uncompleteAttitudes);



  const handlePopoverOpen = (event) => {
    let key = event.currentTarget.getAttribute("data-key");
    let newAnchorEls = new Array(knowledges.length).fill(null); 
    // let newAnchorEls = [...anchorEls]; 
    newAnchorEls[key] = event.currentTarget; 

    setAnchorEls(newAnchorEls);

    let newOpens = new  Array(knowledges.length).fill(false);
    // let newOpens = [...opens];
    newOpens[key] = true;
    setOpens(newOpens);
  };

  const handlePopoverClose = (event) => {
    // let key = event.currentTarget.getAttribute("data-key");
    // let newAnchorEls = [...anchorEls]; 
    // newAnchorEls[key] = null; 
    let newAnchorEls = new Array(knowledges.length).fill(null); 
    // let newOpens = [...opens];
    // newOpens[key] = false;
    let newOpens = new  Array(knowledges.length).fill(false);
    setOpens(newOpens);
    setAnchorEls(newAnchorEls);
  };

  const applied = !!courseElemApplications.find(elemApplication => {
     return (elemApplication[fillingAbilitiesStep+"_id"] === elm.id && elemApplication.applied === true);
  });


  let addClass = ""; 
  if (fillingAbilitiesStep === AbilityFillingStepEnum.STEP_1){
    if (uncompleteSkills.find((element)=>element.id===elm.id)) addClass = "with-error";
  }
  if (fillingAbilitiesStep === AbilityFillingStepEnum.STEP_2){
    if (uncompleteAttitudes.find((element)=>element.id===elm.id)) addClass = "with-error";
  }
    
  return (
      <Grid container className={"knowledge-tick "+ addClass}>

         <Grid  item xs={3} className="d-f ai-c jc-c right-border">

          <AppCircle 
            onClickFunc={()=>storeActions.toggleElementApplication(elm, !applied)} 
            addClass={"margined-1"}
            content={
               applied &&
                <Typography variant="span" className={"text-circle-tick "+color} ><CheckIcon sx={{fontSize: 16}} /></Typography>
              
            }
            size={25}
            background={backgroundColor} />

         </Grid>



        { 
          knowledges.map((knowledge, key) => {

            const elms = courseElems.filter(courseElem => {
               return (courseElem[fillingAbilitiesStep].id === elm.id && courseElem.knowledge?.id === knowledge.id);
            });
            

            return (
              <Grid onMouseLeave={handlePopoverClose} key={key} data-key={key} item xs={3} className="d-f ai-c jc-c right-border">

                <div className="d-f" data-key={key} onMouseEnter={handlePopoverOpen}>
                  {
                    elms.map((courseElem, key)=>{
                      return (
                        <AppCircle
                          key={key}
                          addClass={"margined-1"}
                          content={
                            <Typography variant="span" className={"text-circle-tick "+color}>{t(courseElem.instructor_action.code)}</Typography>
                          }
                          size={25}
                          background={backgroundColor} />

                        );
                    })
                  }
                  
                  {
                    elms.length === 0 &&
                    <AppCircle 
                      size={25}
                      addClass={"margined-1"}
                      background={"second-color-faded"} />
                  }
                  
                </div>

                <InstructorActions 
                  targetKey={key}
                  open={opens[key]}
                  knowledge={knowledge}
                  anchorEl={anchorEls[key]}
                  handlePopoverClose={handlePopoverClose}
                  elm={elm}
                  refBox={refBox}
                  />
              </Grid>
              );
          })
        }
        
        
        
      </Grid>
    );

}
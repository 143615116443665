import Api from 'app/helpers/api'
import i18n from 'app/helpers/i18n'
import {produce} from 'immer'
import useChangesWatcherStore from 'app/store/changesWatcherStore'
import {AbilityFillingStepEnum} from "app/components/shared/enums/global-enums.tsx"

export const courseDetailsSlice = ((set, get) => ({

  course: {},
  courses: [],

  actions: {
    setUnsavedChanges: async (val) => {
      useChangesWatcherStore.getState().functions.setUnsavedChanges(val);
    },

    setCurrentCourse: async (course) => {
      set((state) => ({ course: course }));
    },

    fetchCourse: async (params) => {
      const api = new Api();
      api
        .getCourse(params)
        .then((response) => {
          set((state) => ({ course: response.data.course }));
        })
        .catch((err) => console.log(err));
    },

    deleteCurrentCourse: async () => {
      let course = get().course;
      const api = new Api();
      let params = {}
      params.courseId = course.id;
      api
        .deleteCourse(params)
        .then((response) => {
          set((state) => ({ courses: response.data.courses }));
        })
        .catch((err) => console.log(err));
    },

    fetchCourses: async (params) => {
      const api = new Api();
      api
        .getCourses(params)
        .then((response) => {
          set((state) => ({ courses: response.data.courses }));
        })
        .catch((err) => console.log(err));
    },

    updateOrCreateCourse: async(params, inline=false)=>{
      const api = new Api();
      if (params.course.id){
        const course = params.course;
        params.courseId = course.id;
        api.updateCourse(params)
          .then((response) =>{
              if (!inline) get().actions.goToCourseAbilities(course);
            }
          )
          .catch((err) => console.log(err));
      }else{
        api.addCourse(params)
          .then((response) =>{
              if (response.data.course.skills.length === 0 && response.data.course.attitudes.length === 0){
                get().actions.setSnackMessage({show: true, type:"WARNING",text: i18n.t("error_ai_third_party")})
              }
              
              get().actions.goToCourseAbilities(response.data.course);
            }
          )
        .catch((err) => console.log(err));
      }
    },

    goToCourseAbilities: (course)=>{
      get().navigate(`/courses/${course.id}/abilities`);
    },

    isThereUncompletedAbilities: ()=>{
      let uncompletedAbilities = false;
      if (get().uncompleteSkills.length>0 || get().uncompleteAttitudes.length>0){
        uncompletedAbilities = true;
      }

      const selectedAbilities = get().selectedAbilities;
      for (var i = 0; i < selectedAbilities.length; i++) {
        if (get().actions.isUncompletedAbility(selectedAbilities[i])) uncompletedAbilities = true;
      }
      
      if (uncompletedAbilities){
        get().actions.setSnackMessage({show: true, type:"WARNING",text: i18n.t("uncompleted_ability")})
      }
      return uncompletedAbilities;
    },

    updateCourseAbilities: async () => {
        if (get().actions.isThereUncompletedAbilities()){
          return;
        }

        let statusAttitudes = false;
        let statusSkills = await get().actions.updateCourseElements( AbilityFillingStepEnum.STEP_1);

        if (statusSkills) statusAttitudes = await get().actions.updateCourseElements(AbilityFillingStepEnum.STEP_2);


        if (statusSkills && statusAttitudes) {
          get().actions.setSnackMessage({show: true, type:"SUCCESS",text: i18n.t("course_saved")})
        }else{
          get().actions.setSnackMessage({show: true, type:"ERROR",text:i18n.t("course_not_saved")})
        }
    },


    updateCourseElements: async (cStep) => {
      let elms;
      let elemApplications;

      if (cStep === AbilityFillingStepEnum.STEP_1){
        elms = [...get().courseSkills];
        elemApplications = [...get().courseSkillApplications];
      }else if (cStep === AbilityFillingStepEnum.STEP_2){
        elms = [...get().courseAttitudes];
        elemApplications = [...get().courseAttitudeApplications];
      }

      const courseElemIds = elms.map(courseElem => 
        {
          let courseElemId = {};
          courseElemId[cStep+"_id"] = courseElem[cStep].id;
          courseElemId.knowledge_id= courseElem.knowledge?.id;
          courseElemId.instructor_action_id= courseElem.instructor_action?.id;
          return courseElemId;
        });


      const api = new Api();
      const params = {};
      params.courseId = get().course.id;
      params[cStep+"s"] = courseElemIds;
      params[cStep+"_applications"] = elemApplications;

      let success = false;
      await (cStep === AbilityFillingStepEnum.STEP_1 
        ? api.updateCourseSkills(params) 
        : api.updateCourseAttitudes(params))
        .then((response) => {
          success = true;
          }
        )
        .catch((err) => {
          console.log(err)
        });
      return success;
    },

    removeCourseAbility: () =>{
      let selectedAbilities = [...get().selectedAbilities];
      const ability = selectedAbilities[0];
      
      get().actions.clearAbilityElements(ability, AbilityFillingStepEnum.STEP_1);
      get().actions.clearAbilityElements(ability, AbilityFillingStepEnum.STEP_2);

      selectedAbilities = selectedAbilities.filter(elm => elm.id !== ability.id);

      let abilityDetailesOpen = get().abilityDetailesOpen;
      if (selectedAbilities.length === 0){
        abilityDetailesOpen = false;
      }

      set((state) => ({ selectedAbilities: selectedAbilities, abilityDetailesOpen: abilityDetailesOpen  }));
      get().actions.setUnsavedChanges(true);
    },

    clearAbilityElements: (ability, step) => {
      let cStep = get().fillingAbilitiesStep; 
      if (step) cStep = step;  
      let elms; 
      let elemApplications;

      if (cStep === AbilityFillingStepEnum.STEP_1){
        elms = [...get().courseSkills];
        elemApplications = [...get().courseSkillApplications];
      }else if (cStep === AbilityFillingStepEnum.STEP_2){
        elms = [...get().courseAttitudes];
        elemApplications = [...get().courseAttitudeApplications];
      }

      elms = elms.filter(courseElem => {
             return (courseElem[cStep].ability_id !== ability.id );
          });

      const elmIds = ability[cStep+"s"].map(x => x.id);
      elemApplications = elemApplications.filter(courseElem => {
             return (!elmIds.includes(courseElem[cStep+"_id"]));
          });

      set(produce((state) => { 
        if (cStep === AbilityFillingStepEnum.STEP_1){
          state.courseSkills = elms;
          state.courseSkillApplications = elemApplications;
        }else if (cStep === AbilityFillingStepEnum.STEP_2){
          state.courseAttitudes = elms;
          state.courseAttitudeApplications = elemApplications;
        }

      }));

      get().actions.setUnsavedChanges(true);
    },

    toggleElement: (elm, knowledge, instructorAction) => {
      const cStep = get().fillingAbilitiesStep; 
      let elms; 
      let elemApplications;

      if (cStep === AbilityFillingStepEnum.STEP_1){
        elms = [...get().courseSkills];
        elemApplications = [...get().courseSkillApplications];
      }else if (cStep === AbilityFillingStepEnum.STEP_2){
        elms = [...get().courseAttitudes];
        elemApplications = [...get().courseAttitudeApplications];
      }

      const found = elms.find(courseElem => {
         return (courseElem[cStep].id === elm.id && courseElem.knowledge?.id === knowledge?.id && courseElem.instructor_action?.id === instructorAction?.id);
      });

      // allow only 2 items to be added in instructor actions
      if (instructorAction?.id <= 3){
        let elmToRemove = elms.findIndex(courseElem => {
               return (courseElem[cStep].id === elm.id && courseElem.knowledge?.id === knowledge.id && courseElem.instructor_action?.id <= 3);
            });
        if (elmToRemove>=0) elms.splice(elmToRemove, 1);
      }


      
      const foundWithElemOnly = elms.find(courseElem => {
         return (courseElem[cStep].id === elm.id);
      });

      if (foundWithElemOnly && knowledge === null && instructorAction === null){
        /*when the whole line is unchecked*/
        elms = elms.filter(courseElem => {
               return (courseElem[cStep].id !== elm.id);
            });
        elemApplications = elemApplications.filter(elemApplication => {
              return (parseInt(elemApplication[cStep+"_id"]) !== elm.id);
            });
      }else if (!found){
        /*When it is a new item. simply added it*/
        let obj = {
          knowledge: knowledge,
          instructor_action: instructorAction
        };
        obj[cStep] = elm;
        elms.push(obj);
      }else{
        /*When item already exists, remove it*/
        elms = elms.filter(courseElem => {
               return (courseElem[cStep].id !== elm.id || courseElem.knowledge?.id !== knowledge?.id || courseElem.instructor_action?.id !== instructorAction?.id);
            });
      }

      set(produce((state) => { 
        if (cStep === AbilityFillingStepEnum.STEP_1){
          state.courseSkills = elms;
          state.courseSkillApplications = elemApplications;
        }else if (cStep === AbilityFillingStepEnum.STEP_2){
          state.courseAttitudes = elms;
          state.courseAttitudeApplications = elemApplications;
        }
      }));

      get().actions.refreshUncompleteElms(elm);
      get().actions.setUnsavedChanges(true);
    },


    toggleElementApplication: (elm, applied) => {
      const cStep = get().fillingAbilitiesStep; 

      let elms; 
      let elemApplications;

      if (cStep === AbilityFillingStepEnum.STEP_1){
        elms = [...get().courseSkills];
        elemApplications = [...get().courseSkillApplications];
      }else if (cStep === AbilityFillingStepEnum.STEP_2){
        elms = [...get().courseAttitudes];
        elemApplications = [...get().courseAttitudeApplications];
      }

      const found = elemApplications.find(elemApplication => {
         return (elemApplication[cStep+"_id"] === elm.id);
      });
      

      if (found){
        elemApplications = elemApplications.filter(elemApplication => {
               return (elemApplication[cStep+"_id"] !== elm.id);
            });
      }
      

      if (applied){
        let obj = {
          knowledge: null,
          instructor_action: null
        };
        obj[cStep] = elm;
        elms.push(obj);
      }

      let obj = {
        applied: applied
      };

      obj[cStep+"_id"] = elm.id;

      elemApplications.push(obj);

      set(produce((state) => { 
        if (cStep === AbilityFillingStepEnum.STEP_1){
          state.courseSkills = elms;
          state.courseSkillApplications = elemApplications;
        }else if (cStep === AbilityFillingStepEnum.STEP_2){
          state.courseAttitudes = elms;
          state.courseAttitudeApplications = elemApplications;
        } 
      }));
      get().actions.refreshUncompleteElms(elm);
      get().actions.setUnsavedChanges(true);
    },

    refreshUncompleteElms(element){
      const cStep = get().fillingAbilitiesStep; 

      let elms; 
      let elemApplications;
      let uncompleteElms;

      if (cStep === AbilityFillingStepEnum.STEP_1){
        elms = [...get().courseSkills];
        uncompleteElms = [...get().uncompleteSkills];
        elemApplications = [...get().courseSkillApplications];
      }
      if (cStep === AbilityFillingStepEnum.STEP_2){
        elms = [...get().courseAttitudes];
        uncompleteElms = [...get().uncompleteAttitudes];
        elemApplications = [...get().courseAttitudeApplications];
      }

      const nullElmFound = elms.find(elm => {
                   return (elm[cStep].id === element.id && elm.knowledge === null && elm.instructor_action === null);
                });

      const fullElm = elms.find(elm => {
                   return (elm[cStep].id === element.id && elm.knowledge !== null && elm.instructor_action !== null);
                });

      const elmApplication = elemApplications.find(elmApp => {
                   return (elmApp[cStep+"_id"] === element.id && elmApp.applied === true);
                });


      if (nullElmFound && !fullElm && !elmApplication){
        uncompleteElms.push(element);
      }else{
        let elmInx = uncompleteElms.findIndex((elm)=> elm.id === element.id);
        if (elmInx>=0) uncompleteElms.splice(elmInx, 1);
      }
      

      set(produce((state) => { 
        if (cStep === AbilityFillingStepEnum.STEP_1){
          state.uncompleteSkills = uncompleteElms;
        }
        if (cStep === AbilityFillingStepEnum.STEP_2){
          state.uncompleteAttitudes = uncompleteElms;
        } 
      }));
    }


  }

}))

import { create } from 'zustand'
import { usersSlice } from 'app/store/slices/usersSlice'
import { courseDetailsSlice } from 'app/store/slices/courseDetailsSlice'
import { courseSkillsSlice } from 'app/store/slices/courseSkillsSlice'
import { courseAttitudesSlice } from 'app/store/slices/courseAttitudesSlice'
import { abilitiesSlice } from 'app/store/slices/abilitiesSlice'
import { instructorActionsSlice } from 'app/store/slices/instructorActionsSlice'
import { knowledgesSlice } from 'app/store/slices/knowledgesSlice'
import { utilsSlice } from 'app/store/slices/utilsSlice'
import { disciplinesSlice } from 'app/store/slices/disciplinesSlice'
import { programsSlice } from 'app/store/slices/programsSlice'
import { inputTypesSlice } from 'app/store/slices/inputTypesSlice'
import { universitiesSlice } from 'app/store/slices/universitiesSlice'
import { academicTermsSlice } from 'app/store/slices/academicTermsSlice'
import { academicYearsSlice } from 'app/store/slices/academicYearsSlice'
import { cachingSlice } from 'app/store/slices/cachingSlice'

const useStore = create((...a) => ({
  ...usersSlice(...a),
  ...courseDetailsSlice(...a),
  ...courseSkillsSlice(...a),
  ...courseAttitudesSlice(...a),

  ...abilitiesSlice(...a),
  ...instructorActionsSlice(...a),
  ...knowledgesSlice(...a),
  ...disciplinesSlice(...a),
  ...programsSlice(...a),
  ...universitiesSlice(...a),
  ...academicTermsSlice(...a),
  ...academicYearsSlice(...a),
  ...inputTypesSlice(...a),

  ...utilsSlice(...a),
  ...cachingSlice(...a),
  actions:{
    ...usersSlice(...a).actions,
    ...courseDetailsSlice(...a).actions,
    ...courseSkillsSlice(...a).actions,
    ...courseAttitudesSlice(...a).actions,

    ...abilitiesSlice(...a).actions,
    ...instructorActionsSlice(...a).actions,
    ...knowledgesSlice(...a).actions,
    ...disciplinesSlice(...a).actions,
    ...programsSlice(...a).actions,
    ...universitiesSlice(...a).actions,
    ...academicTermsSlice(...a).actions,
    ...academicYearsSlice(...a).actions,
    ...inputTypesSlice(...a).actions,

    ...utilsSlice(...a).actions,
    ...cachingSlice(...a).actions,
  }
}));

export default useStore; 
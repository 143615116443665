import { createTheme } from "@mui/material";
import variables from "app/helpers/_variables.module.scss";

export const buttonTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#fff",
          borderRadius: "20px",
          textTransform: "none",
          textAlign: "center",
          lineHeight: "24px",
          fontSize: "16px",
        },
      },
      variants: [
        {
          props: { variant: "primary" },
          style: {
            background: variables["third-color"],
            fontSize: "16px",
            fontFamily: variables["font-2"],
            fontWeight: "600",
            "&:hover": {
              backgroundColor: variables["third-color-faded"],
            },
          },
        },
        {
          props: { variant: "default" },
          style: {
            background: variables["main-color"],
            fontFamily: variables["font-3"],
            fontWeight: "700",
            "&:hover": {
              color: variables["main-color"],
              backgroundColor: variables["main-color-faded"],
            },
            "&:disabled": {
              color: "#fff",
            },
          },
        },
        {
          props: { variant: "secondary" },
          style: {
            background: variables["fourth-color-faded"],
            fontFamily: variables["font-2"],
            fontWeight: "600",
            "&:hover": {
              backgroundColor: variables["fourth-color-faded-2"],
            },
          },
        },
        {
          props: { variant: "regular" },
          style: {
            color: "#000",
            background: "#FFF",
            boxShadow: "0px 1px 61px 1px rgba(0, 0, 0, 0.25)",
            backdropFilter: "blur(10px)",
            fontFamily: variables["font-2"],
          },
        },
        {
          props: { variant: "text" },
          style: {
            textTransform: "none",
            color: variables["third-color"],
            textAlign: "center",
            fontSize: "16px",
            fontFamily: variables["font-2"],
            fontWeight: "600",
            "&:hover": {
              color: variables["third-color-faded"],
            },
          },
        },
        {
          props: { variant: "text-active" },
          style: {
            color: variables["main-color"],
            fontSize: "36px",
            fontFamily: variables["font-2"],
            fontWeight: "600",
            padding: "3px",
            textShadow: "2px 2px 5px #979292",
          },
        },
        {
          props: { variant: "text-outline" },
          style: {
            color: variables["third-color"],
            fontFamily: variables["font-2"],
            border: "1px solid " + variables["third-color"],
            background: variables["third-color-faded-2"],
            fontWeight: "600",
            "&:hover": {
              color: variables["third-color-faded"],
            },
          },
        },
        {
          props: { size: "large" },
          style: {
            padding: "16px 50px",
          },
        },
        {
          props: { size: "medium" },
          style: {
            padding: "6px 24px",
          },
        },
        {
          props: { size: "small" },
          style: {
            padding: "1px 20px",
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "16px",
          },
        },
        {
          props: { coloro: "main-faded" },
          style: {
            color: variables["main-color"],
            background: variables["main-color-opac"],
            borderColor: variables["main-color"],
            "&:hover": {
              backgroundColor: variables["main-color-faded"],
            },
          },
        },
        {
          props: { coloro: "fourth-faded" },
          style: {
            color: "#fff",
            background: variables["fourth-color-faded"],
            "&:hover": {
              color: "#fff",
              backgroundColor: variables["fourth-color"],
            },
          },
        },
        {
          props: { variant: "text", coloro: "fourth-faded" },
          style: {
            textTransform: "none",
            color: variables["fourth-color-faded"],
            backgroundColor: "rgba(0, 0, 0, 0)"
          },
        },

        {
          props: { coloro: "fifth" },
          style: {
            color: "#fff",
            background: variables["fifth-color"],
            "&:hover": {
              color: "#fff",
              backgroundColor: variables["fifth-color-faded"],
            },
          },
        },
        {
          props: { coloro: "fifth-faded" },
          style: {
            color: variables["fifth-color"],
            borderColor: variables["fifth-color"],
            backgroundColor: variables["fifth-color-faded-2"],
            "&:hover": {
              color: "#fff",
              backgroundColor: variables["fifth-color-faded"],
            },
          },
        },
        {
          props: { variant: "text", coloro: "fifth" },
          style: {
            textTransform: "none",
            color: variables["fifth-color"],
            backgroundColor: "rgba(0, 0, 0, 0)"
          },
        },
        {
          props: {  variant: "default", coloro: "sixth-faded" },
          style: {
            textTransform: "none",
            backgroundColor: variables["sixth-color-faded"]
          },
        },
      ],
    },
  },
});

/* Enable the new theme overrides to be used wihtout errors */
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    dashed: true;
    secondary: true;
    primary: true;
    regular: true;
    "text-outline": true;
    "text-active": true;
  }
}

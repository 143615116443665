import React, {useEffect} from "react"
import {useNavigate} from "react-router-dom";
import Container from '@mui/material/Container';
import {Link} from "react-router-dom";
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import {
  ButtonTypeEnum, ButtonSizeEnum
} from "app/components/shared/enums/global-enums.tsx";
import AppButton from "app/components/shared/AppButton/AppButton.tsx";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next'
import Footer from "app/components/shared/Footer/Footer";
import Navbar from "app/components/shared/Navbar/Navbar.tsx";
import {AppRoutes} from "app/helpers/routes";
import signUpImg from "app/assets/images/image-users.gif"
import useStore from 'app/store/store'
import './SignUp.scss'

const SignUpSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('Required'),
  last_name: Yup.string()
    .required('Required'),
  email: Yup.string().required('Required').email(),
  university_id: Yup.number()
    .required('Required'),
  password: Yup.string().required('Password is required')
    .min(8, 'Password should be 8 chars minimum.'),
  passwordConfirmation: Yup.string().required('Required')
    .oneOf([Yup.ref('password')], 'Passwords must match')
});

export default function SignUp (){

  const {t} = useTranslation();
  const navigate = useNavigate();

  const storeActions = useStore((state)=> state.actions);
  
  const universities = useStore((state) => state.universities);
  const accessToken = storeActions.retrieveItem("user-access-token");

  const initValues = {
            first_name: '',
            last_name: '',
            email: '',
            university_id: '',
            password: '',
            passwordConfirmation: ''
          };


  useEffect(() =>{
    storeActions.fetchUniversities();
  }, [storeActions]);


  useEffect(() =>{
    if (accessToken) {
      navigate(AppRoutes.dashboardPath);
    }
  }, [navigate, accessToken]);

  return (
    <>
      <Navbar/>
      <Container maxWidth="xl" >
        <div className="v-space-5"></div>
        <Grid container spacing={5}>
          <Grid item lg={7}>
            <Formik
              enableReinitialize={true} 
              initialValues={initValues}
              validationSchema={SignUpSchema}
              onSubmit={(values)=>{
                  storeActions.signUp({user: values}, navigate);
                }
              }
            >
            {({ errors, touched }) => (
              <Form>
                <Grid container  style={{padding: 20}}  className="signup-container">
                  <Grid item>
                    <Typography variant="span" className="second-color font-2" style={{fontSize: 32, fontWeight: 700}}>
                      {t("Create an account")}
                    </Typography>
                  </Grid>
                  <div className="v-space-5"></div>

                  <Grid  container  spacing={2}>
                    <Grid container item lg={6} className="d-f fd-c">
                      <Typography variant="span" className="second-color font-2" style={{fontSize: 16}}>
                        {t("Family name")}
                      </Typography>
                      <div className="v-space-3"></div>
                      <Field  id="first_name" className="font-2 field w-100" name="first_name" placeholder={t("First Name")} />
                      <div className="v-space-1"></div>
                      {errors.first_name && touched.first_name ? <div className="error-color">{errors.first_name}</div> : null}
                      <div className="v-space-3"></div>
                    </Grid>
                    <Grid container item lg={6} className="d-f fd-c">
                      <Typography variant="span" className="second-color font-2" style={{fontSize: 16}}>
                        {t("Last Name")}
                      </Typography>
                      <div className="v-space-3"></div>
                      <Field  id="last_name" className="font-2 field w-100" name="last_name" placeholder={t("Last Name")} />
                      <div className="v-space-1"></div>
                      {errors.last_name && touched.last_name ? <div className="error-color">{errors.last_name}</div> : null}
                      <div className="v-space-3"></div>
                    </Grid>
                  </Grid>

                  <Grid container item lg={12} className="d-f fd-c">
                    <Typography variant="span" className="second-color font-2" style={{fontSize: 16}}>
                      {t("Email")}
                    </Typography>
                    <div className="v-space-3"></div>
                    <Field   id="email" className="font-2 field w-100" name="email" placeholder={t("Email")} />
                    <div className="v-space-1"></div>
                    {errors.email && touched.email ? <div className="error-color">{errors.email}</div> : null}
                    <div className="v-space-3"></div>
                  </Grid>

                  <Grid container item lg={12} className="d-f fd-c">
                    <Typography variant="span" className="second-color font-2" style={{fontSize: 16}}>
                      {t("University")}
                    </Typography>
                    <div className="v-space-3"></div>
                    <Field  className="dropbox font-2" as="select" name="university_id">
                      <option value="">{t('--University--')}</option>
                      {
                        universities.map((university, key)=>{
                          return(
                            <option key={key}  value={university.id}>{university.name}</option>
                            )
                        })
                      }
                    </Field>
                    <div className="v-space-1"></div>
                    {errors.university_id && touched.university_id ? <div className="error-color">{errors.university_id}</div> : null}
                    <div className="v-space-3"></div>
                  </Grid>

                  <Grid  container  spacing={2}>
                    <Grid container item lg={6} className="d-f fd-c">
                      <Typography variant="span" className="second-color font-2" style={{fontSize: 16}}>
                        {t("Password")}
                      </Typography>
                      <div className="v-space-3"></div>
                      <Field  type="password" id="password" className="font-2 field w-100" name="password" placeholder={t("Password")} />
                      <div className="v-space-1"></div>
                      {errors.password && touched.password ? <div className="error-color">{errors.password}</div> : null}
                      <div className="v-space-3"></div>
                    </Grid>
                    <Grid container item lg={6} className="d-f fd-c">
                      <Typography variant="span" className="second-color font-2" style={{fontSize: 16}}>
                        {t("Confirm your Password")}
                      </Typography>
                      <div className="v-space-3"></div>
                      <Field  id="passwordConfirmation" type="password" className="font-2 field w-100" name="passwordConfirmation" placeholder={t("Confirm your Password")} />
                      <div className="v-space-1"></div>
                      {errors.passwordConfirmation && touched.passwordConfirmation ? <div className="error-color">{errors.passwordConfirmation}</div> : null}
                      <div className="v-space-3"></div>
                    </Grid>
                  </Grid>

                  <Grid  container  item>
                    
                    <Typography variant="span" className="second-color font-2" style={{fontSize: 16}}>
                      {t("passwod_hint_structure")}
                    </Typography>
                      
                  </Grid>
                  <div className="v-space-5"></div>
                  <Grid  container spacing={5} item>
                    <Grid container item lg={6} className="ai-c">
                      <Link to={AppRoutes.signInPath}> 
                        <Typography variant="span" className="second-color font-2 underline" style={{fontSize: 16}}>
                         {t('Log In instead')}
                        </Typography>
                      </Link>
                    </Grid>
                    <Grid container item lg={6} className="jc-r">
                      <AppButton 
                        actionType="submit"
                        buttonSize={ButtonSizeEnum.LARGE}
                        sx={{fontWeight: 400}}
                        buttonLabel={t('Create an Account')}
                        buttonType={ButtonTypeEnum.DEFAULT}
                      />
                    </Grid>
                  </Grid>


                </Grid>
              </Form>
              )}
            </Formik>
          </Grid>
          <Grid item lg={5}>
            <img src={signUpImg} className="w-100" alt=""  />
          </Grid>
          <div className="v-space-10"></div>
        </Grid>
      </Container>
      <Footer/>
    </>
    )
}
import React from "react"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {useSearchParams} from 'react-router-dom';

import {AbilityFillingStepEnum} from "app/components/shared/enums/global-enums.tsx"
import SimpleBar from "simplebar-react"


import 'simplebar-react/dist/simplebar.min.css';
import './SelectedAbilitiesDetails.scss';

export default function SelectedAbilitiesDetails({selectedAbilities, unqCourseElms, fAS}){

  let [searchParams, setSearchParams] = useSearchParams();



  let mainColor;
  if (fAS === AbilityFillingStepEnum.STEP_1) {
    mainColor = "third-color";
  }
  if (fAS === AbilityFillingStepEnum.STEP_2) {
    mainColor = "fifth-color";
  }

  let cptAbility = 0;
  let cptElm = 0;


  function editElm(ability, elm){
    searchParams.set("ability", ability.id);
    if (elm){
      searchParams.set("elm", elm.id);
      searchParams.set("elmType", fAS);
    }
    searchParams.delete("visualisation");
    searchParams.set("details", 1);
    setSearchParams(searchParams);
  }

  return (
    <Box id="js-sel-ab-container" className={"sel-ab-container "+fAS}>
      <SimpleBar  className="h-100 " >
      {
        selectedAbilities.map((ability, key)=>{
          cptAbility++;
          return (
            <Box key={key}>
              <Typography onClick={()=>editElm(ability, null)} variant="span" sx={{fontSize: 16, fontWeight: 700}} className={"selected-ability font-4 "+mainColor+" js-ab-"+fAS+"-"+ability.id}>
                {cptAbility}&nbsp;&nbsp;&nbsp;{ability.name}
              </Typography>

              {
                unqCourseElms.filter((elm)=> elm.ability_id === ability.id).map((elm, key1)=>{
                  cptElm++;
                  return (
                    <Box onClick={()=>editElm(ability, elm)} className={"selected-item js-elm-"+fAS+"-"+elm.id} key={key1}>
                      <Typography variant="span" sx={{fontSize: 12, fontWeight: 400}} className={"font-2 "+mainColor}>
                        {cptElm}&nbsp;&nbsp;&nbsp;{elm.name}&nbsp;
                        <EditOutlinedIcon className="edit-icon" sx={{fontSize: 13, fontWeight: 700}}  />
                      </Typography>
                    </Box>
                    )

                })
              }
            </Box>
            )

        })
      }

      </SimpleBar>
    </Box>
    )
}
import Api from 'app/helpers/api'

export const courseSkillsSlice = ((set, get) => ({
  courseSkills: [],
  courseSkillApplications: [],

  uncompleteSkills: [],

  actions: {
    fetchCourseSkills: async (params) => {
      const api = new Api();
      api.getCourseSkills(params)
        .then((response) => {
          set((state) => ({ 
            courseSkills: response.data.course_skills,
            courseSkillApplications: response.data.course_skill_applications
          }));
          get().actions.refreshSelectedAbilities();
        })
      .catch((err) => console.log(err));
    }

  }

}))
import Api from 'app/helpers/api'

export const knowledgesSlice = ((set, get) => ({
  knowledges: [],

  actions: {
    fetchKnowledges: async (params) => {
      // let cachedKnowledges = get().actions.retrieveItem("knowledges");
      // if  (cachedKnowledges){
      //   set((state) => ({ knowledges: cachedKnowledges }));
      //   return;
      // }
      const api = new Api();
      api.getKnowledges()
        .then((response) => {
          set((state) => ({ knowledges: response.data.knowledges }));
          // get().actions.storeItem("knowledges", response.data.knowledges);
        })
      .catch((err) => console.log(err));
    },

  }

}))
import React from "react";

import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import useStore from 'app/store/store'
import './AbilityTick.scss';

export default function AbilityTick({sideBarOpen, ability, parentOnTickClickFunc}) {

  const storeActions = useStore((state) => state.actions);

  const selectedAbilities = useStore((state) => state.selectedAbilities);

  const selectedAbility = selectedAbilities.find((elm) => elm.id === ability.id);

  let isShownAbility = false;
  if (selectedAbilities.length > 0){
    isShownAbility = (ability.id === selectedAbilities[0].id);
  }

  function handleCheckClick(event){
    event.preventDefault();
    if (event.target.checked){
      event.stopPropagation();
    }
    storeActions.toggleSelectAbility(ability);
  }

  return (
    <Box className={`  ${isShownAbility ? 'active bg-main-color-faded-2' : 'bg-second-color-faded'}`}  
      onClick={() => storeActions.pushAbilityToTop(ability)}
     >
      <Grid container  sx={{pt:0.9, pb:0.9, pl:1}}>
        <Grid item xs className="d-f ai-c ">
            <Box className={`tick-circle  center-el d-f ai-c jc-c  ${isShownAbility ? 'active bg-main-color-faded' : 'bg-second-color-faded-2'}`}>
              <Typography variant="span" className="main-color-dark" sx={{fontSize: 16, fontWeight: 500}}>
                {ability.order_number}
              </Typography>
            </Box>
        </Grid>
        {sideBarOpen &&
        <Grid item xs={8} className="d-f ai-c " >
              <Typography variant="span" >
                {ability.name}
              </Typography>
        </Grid>
        }
        <Grid item xs className=" d-f ai-c ">

          <Checkbox className={`center-el ${isShownAbility ? 'ch-main': ''}`} label="Label" color="default" checked={!!selectedAbility}  onClick={handleCheckClick} />
          
        </Grid>
      </Grid>
    </Box>
  );
}

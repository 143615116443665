import React from "react"
import TextField from "@mui/material/TextField"
import Autocomplete from '@mui/material/Autocomplete';
import Grid from "@mui/material/Grid"

import { useFormik } from 'formik';
import * as Yup from 'yup';

import {useTranslation} from "react-i18next"

import AppButton from "app/components/shared/AppButton/AppButton.tsx"
import {ButtonTypeEnum, ButtonSizeEnum, ButtonColorEnum, AbilityFillingStepEnum} from "app/components/shared/enums/global-enums.tsx"


import useStore from 'app/store/store'
// import './AddAbility.scss'

export default function AddAbilityElement(){

  const {t} = useTranslation();
  const storeActions = useStore((state) => state.actions);
  const fillingAbilitiesStep = useStore((state) => state.fillingAbilitiesStep);
  const abilities = useStore((state) => state.abilities);



  const allElms = abilities.map((ability) => (fillingAbilitiesStep === AbilityFillingStepEnum.STEP_1 ?ability.skills: ability.attitudes)).flat();
  const elmNames = [...new Set(allElms.map((elem)=>elem.name))];

  const ElmSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .required('Required'),
    description: Yup.string()
     .min(2, 'Too Short!')
     .required('Required')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      description: ''
    },
    validationSchema: ElmSchema,
    onSubmit: (values) => {
      storeActions.createAbilityElement(values)
    },
  });

  return (
    
      <form onSubmit={formik.handleSubmit}>
        <Grid item xs={12}  className="spec-height">
          <Grid container sx={{paddingLeft: 1, paddingRight: 1} } rowSpacing={1}>
            <Grid item xs={12} className="d-f ai-c jc-sb">
             <Autocomplete
                className="w-100"
                freeSolo
                options={
                  elmNames
                }
                id="name"
                value={formik.values.name}
                name="name"
                renderInput={(params) => <TextField  {...params} 
                size="small" label={t("Your Custom "+fillingAbilitiesStep)}
                error={formik.touched.name && Boolean(formik.errors.name)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />}
                onInputChange={(event, value) => 
                  {formik.values.name = value}
                }
             />

            </Grid>
            <Grid item xs={12} className="d-f ai-c jc-sb">
             <TextField
                id="description"
                name="description"
                multiline
                maxRows={4}
                label={t("Short Description")}
                className="w-100"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.description && Boolean(formik.errors.description)}
             />
             
            </Grid>
            <Grid item xs={12} className="d-f ai-c jc-sb">
              <AppButton 
               buttonType={ButtonTypeEnum.TEXT_OUTLINE} 
               buttonLabel={t("Cancel")}
               buttonSize={ButtonSizeEnum.SMALL}
               onClickFunc={storeActions.toggleFillingNewElm}

               buttonColor={fillingAbilitiesStep ===AbilityFillingStepEnum.STEP_2 ? ButtonColorEnum.FIFTH_FADED: ""}
               />

              <AppButton 
               buttonType={ButtonTypeEnum.DEFAULT} 
               buttonLabel={t("Create "+fillingAbilitiesStep)}
               buttonSize={ButtonSizeEnum.SMALL}
               actionType="submit"
               />
            </Grid>
          </Grid>  
        </Grid>
      </form>
  );
}
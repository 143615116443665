import Api from 'app/helpers/api'

export const inputTypesSlice = ((set, get) => ({
  inputTypes: [],

  actions: {
    fetchInputTypes: async (params) => {
      const api = new Api();
      api.getInputTypes()
        .then((response) => {
          set((state) => ({ inputTypes: response.data.input_types }));
        })
      .catch((err) => console.log(err));
    },

  }

}))
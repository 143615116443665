import React from "react"
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {Link} from "react-router-dom"
import {useTranslation} from 'react-i18next'

import {AppRoutes} from "app/helpers/routes"

import {
  DataRecordType
} from "app/components/shared/enums/global-enums.tsx";

import "./Menu.scss"

export default function TickItems ({collaborate=false, dashboard=false, accreditation=false}){

  const {t} = useTranslation();

  return (
    
    <Grid container>
      <Grid item xs={12} className="item ">
        <Link to={AppRoutes.dashboardPath} > 
          <Typography variant="span" className={"second-color font-2 "+(dashboard? 'active': '')}>
            {t("Dashboard")}
          </Typography>
        </Link>
      </Grid>
      <Grid  item xs={12} className="item ">
        <Link to={AppRoutes.collaboratePath} >
          <Typography variant="span" className={"second-color font-2 "+(collaborate? 'active': '')}>
            {t("Collaborate")}
          </Typography>
        </Link>
      </Grid>

      <Grid  item xs={12} className="item ">
        <Link to={AppRoutes.newCoursePath+'?type='+DataRecordType.ACCREDITATION} >
          <Typography variant="span" className={"second-color font-2 "+(accreditation? 'active': '')}>
            {t("Accreditations & others")}
          </Typography>
        </Link>
      </Grid>
    </Grid>
  )
}
import React, {useState} from "react";
import { useTranslation } from "react-i18next";

import AppButton from "app/components/shared/AppButton/AppButton.tsx";
import Grid from "@mui/material/Grid";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { navList } from "./utils/navlist.tsx";
import "./Navbar.scss";
import logo from "app/assets/images/logo.png";
import profilePicture from "app/assets/images/profilePicture.jpg";
import { useNavigate, useLocation } from "react-router-dom";
import {AppRoutes} from "app/helpers/routes"
import {
  ButtonTypeEnum,
  ButtonSizeEnum,
  ButtonColorEnum
} from "app/components/shared/enums/global-enums.tsx";
import useStore from 'app/store/store'

export default function Navbar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();


  const storeActions = useStore((state)=> state.actions);
  const user = useStore((state)=> state.user);

  const handleClick = (urlPath: string) => {
    !location.pathname.includes(urlPath) &&
      navigate(urlPath, { replace: true });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };


  return (
    <div className="d-f jc-sb ai-c">
      <div className="d-flex-row logo" onClick={() => navigate("/")}>
        <img src={logo} alt="logo" />
      </div>
      <Grid
        container
        item
        xs
        spacing={2}
        sx={{ padding: 2 }}
        className="d-f jc-r bs-bx"
      >
        {navList.map((nav, key) => {

          return (
            <React.Fragment key={key}>
            {
              ((user && user.id && !nav.userNotLogged) || !user || !user.id) &&
              <Grid item className="d-f ai-c" key={t(nav.buttonLabel)}>
                <AppButton
                  onClickFunc={() => {
                    handleClick(nav.redirectUrl);
                  }}
                  buttonLabel={nav.buttonLabel}
                  buttonType={nav.buttonType}
                  buttonSize={nav.buttonSize}
                  buttonEndIcon={nav.buttonEndIcon}
                />
              </Grid>
            }
            </React.Fragment>
          );
        })}

        {
         (user && user.id) &&
          <>
          <Grid item className="d-f ai-c" >
            <AppButton
              buttonLabel={user.first_name}
              buttonType={ButtonTypeEnum.TEXT}
              buttonSize={ButtonSizeEnum.MEDIUM}
              buttonColor={ButtonColorEnum.FOURTH}
              onClickFunc={openMenu}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={closeMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={()=>handleClick(AppRoutes.dashboardPath)}>Dashboard</MenuItem>
              <MenuItem >Profile</MenuItem>
              <MenuItem onClick={()=>storeActions.logout(navigate)}>Logout</MenuItem>
            </Menu>
          </Grid>
          <Grid item className="d-f ai-c" >
            <img src={profilePicture} alt={user.first_name} className="profile-picture" />
          </Grid>
          </>
        }
      </Grid>
    </div>
  );
}

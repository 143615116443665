import React, {useRef, useState, useEffect} from "react"
import Container from '@mui/material/Container';
import Footer from "app/components/shared/Footer/Footer";
import Navbar from "app/components/shared/Navbar/Navbar.tsx";
import ListCourses from "app/components/Collaborate/ListCourses/ListCourses";
import CoursesLegend from "app/components/Collaborate/CoursesLegend/CoursesLegend";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useDebouncedCallback } from "use-debounce";

import cssVars from 'app/helpers/_variables.module.scss';
import { Stage, Layer, Text, Circle, Group, Line, Arc } from 'react-konva';
import {useTranslation} from 'react-i18next'

import {AbilityFillingStepEnum, CollaborateViewEnum} from "app/components/shared/enums/global-enums.tsx"
import TickItems from "app/components/Collaborate/TickItems/TickItems"
import Menu from "app/components/Menu/Menu"

import useStore from 'app/store/store'
import './Collaborate.scss'


export default function Collaborate(){
  const stageContainer = useRef();
  const {t} = useTranslation();
  let fAS = useStore((state) => state.fillingAbilitiesStep);
  const cV = useStore((state) => state.collaborateView);
  const program = useStore((state) => state.program);
  let storeActions = useStore((state) => state.actions);

  let abilities = [...useStore((state) => state.abilities)];
  const courses = useStore((state) => state.courses);

  const [filteredCourses, setFilteredCourses] = useState([]);

  const [openLC, setOpenLC] = useState(false);

  let mainColor1, gap;

  let abilityNbDots = [];

  if (fAS === AbilityFillingStepEnum.STEP_1){
    mainColor1 = cssVars["third-color"]; 
  }
  if (fAS === AbilityFillingStepEnum.STEP_2){
    mainColor1 = cssVars["fifth-color"]; 
  }

  let cptLine = -4, cptAbility=0, cptCircle=0, cptLine2=-4;
  let gapAngle = 360/abilities.length;
  
  const [cWidth, setCWidth] = useState(null);
  const [cHeight, setCHeight] = useState(null);

  let w, h, center, kernelRad = 45;
  const maxRad = 330;
  const abLineWidth=maxRad+20;
  if (stageContainer.current){
    w = stageContainer.current.offsetWidth;
    h = stageContainer.current.offsetHeight;
    center = [cWidth/2, cHeight/2];
  }

  const radCircle = (maxRad-kernelRad)/filteredCourses.length;
  const minRadPoint = 2.5, maxRadPoint = 5;
  const radPointCof = (maxRadPoint-minRadPoint)/filteredCourses.length;

  /*this define the starting angle*/
  function angleToDestPoints(x1, y1, length, angle) {
      angle *= Math.PI / 180;
      var x2 = x1 + length * Math.cos(angle), y2 = y1 + length * Math.sin(angle);
      return [x2, y2];
  }

  function changeWidthAndHeight() {
    w = stageContainer.current.offsetWidth;
    h = stageContainer.current.offsetHeight;
    setCWidth(w); setCHeight(h);
  }

  const handleResize = useDebouncedCallback(
    changeWidthAndHeight,
    500
  );

  useEffect(() => {
    storeActions.fetchCourses({type: cV, program_id: (program ? program.id : null)});
    
  }, [cV, program, storeActions]);

  useEffect(() => {
    if (cV !== CollaborateViewEnum.VIEW_4){
      let courses_ = courses;
      courses_.sort((a, b) => (a.ord_numb > b.ord_numb ? 1 : -1));
      setFilteredCourses(courses_);
    }else{
      setFilteredCourses([]);
    }

    handleResize();
  }, [cV, courses, handleResize]);

  useEffect(() => {
    storeActions.fetchPrograms();
    storeActions.fetchAbilities();
    storeActions.fetchKnowledges();
    storeActions.fetchInstructorActions();

  }, [storeActions]);


  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return _ => {
      window.removeEventListener('resize', handleResize);
    }
  }, [handleResize]);
  



  return (
    <>
      <Navbar/>
      <Container maxWidth="xl" >
        <div className="v-space-10"></div>
        <Grid  container >
          <Grid item md={1.5}>
            <Menu collaborate={true}/>
          </Grid>
          <Grid item md={8.5}>
            <Box className="w-100 p-r collaborate-container" ref={stageContainer} >
            <div className="v-space-7" ></div>
            
              
              <TickItems setOpenLC={setOpenLC} />

              {
                cWidth && 
                <Stage width={cWidth} height={850} >
                <Layer>
                  {
                    abilities.map((ability, key1) => {
                      cptAbility++;
                      cptLine++;
                      let startingAngle = gapAngle*cptLine;

                      let titleAngle = startingAngle+(gapAngle/2);
                      const pnsTitleElm = angleToDestPoints(center[0], center[1] , abLineWidth, titleAngle);

                      abilityNbDots.push(0);

                      return (
                        <Group key={key1}>
                          <Text text={cptAbility} fill={mainColor1} align="center" verticalAlign="middle" x={pnsTitleElm[0]} y={pnsTitleElm[1]} fontSize={24} fontStyle="bold" fontFamily={cssVars["font-1"]} padding={0} lineHeight={0} width={20} height={20} offsetX={10}  offsetY={10} />

                          <Line
                            x={center[0]} y={center[1]} stroke={mainColor1} strokeWidth={1.0}
                            dash={[7, 5]} points={[0, 0, ...angleToDestPoints(0, 0, abLineWidth, (gapAngle*cptLine))]}
                                  />

                        </Group>
                      )

                    })
                  }


                  {
                    filteredCourses.map((course, key1) => {
                      cptCircle++;
                      let cRadCircle = kernelRad+1+(radCircle*cptCircle);

                      return (
                      <Group key={key1}>
                        {
                          abilities.map((ability, key2) => {
                            cptLine++;
                            let cptLineElm=0;
                            let startingAngle = gapAngle*cptLine;
                            
                            let inGapAngle = gapAngle/(ability[fAS+"s"].length+1);

                            return (
                              <Group key={key2}>
                                {
                                  ability[fAS+"s"].map((elm, key3)=>{
                                    cptLineElm++;
                                    const cirLocation = angleToDestPoints(center[0], center[1], cRadCircle, startingAngle+(inGapAngle*cptLineElm));

                                    let elmFound = course[fAS+"s"].find((elmBis)=> (elm.id===elmBis.id));

                                    if (elmFound){
                                      abilityNbDots[key2] += 1;
                                    }

                                    return (
                                      <Group key={key3}>
                                        {elmFound &&
                                          <>
                                          <Circle key={key3} x={cirLocation[0]} y={cirLocation[1]} radius={minRadPoint+(radPointCof*(key1+1))}  fill={course.color} />
                                          
                                          {
                                            fAS === AbilityFillingStepEnum.STEP_2 &&
                                            <Circle key={key3} x={cirLocation[0]} y={cirLocation[1]} radius={3+minRadPoint+(radPointCof*(key1+2))}  fill={course.color+"33"} />
                                          }
                                          </>
                                        }
                                      </Group>   
                                      )
                                  })
                                }
                              </Group>
                            )

                          })
                        }

                        <Circle x={center[0]} y={center[1]} radius={cRadCircle}  stroke="#999" strokeWidth={0.2} />

                        
                      </Group>

                      )

                    })
                  }
                  
                  <Circle x={center[0]} y={center[1]} radius={kernelRad} fill={mainColor1} />

                  <Text text={fAS.toUpperCase()+ " "+ t("MAP")} align="center" verticalAlign="middle" color="white" width={kernelRad+20} height={kernelRad+20} fill="white" x={center[0]} y={center[1]} fontSize={13} fontStyle="bold" fontFamily={cssVars["font-2"]} offsetX={(kernelRad+20)/2} offsetY={(kernelRad+20)/2} />

                  {
                    abilityNbDots.map((nbDots, key)=>{
                      cptLine2++;
                      let startingAngle = gapAngle*(cptLine2);
                      let titleAngle = startingAngle+(gapAngle/2);
                      const pnsGapElm = angleToDestPoints(center[0], center[1] , abLineWidth+40, titleAngle);

                      gap = Math.max(...abilityNbDots) * 0.3;

                      if (filteredCourses.length === 0 || nbDots > gap){
                        return null;
                      }

                      return (
                        <Group key={key}>
                          <Arc
                            x={center[0]} y={center[1]}
                            innerRadius={maxRad+40}
                            outerRadius={maxRad+40}
                            angle={gapAngle}
                            rotation={startingAngle}
                            stroke="red"
                            strokeWidth={3.0}
                            dash={[5, 11]}
                          />
                          <Text text={"GAP"} fill="red" align="center" verticalAlign="middle" x={pnsGapElm[0]} y={pnsGapElm[1]} fontSize={24} fontStyle="bold" fontFamily={cssVars["font-1"]} padding={0} lineHeight={0} width={40} height={20} offsetX={10}  offsetY={10} />
                        </Group>
                        )

                    })
                  }



                </Layer>
              </Stage>
              }
              <div className="v-space-7"></div>
            </Box>
            <div className="v-space-10"></div>
          </Grid>

          
          <Grid item md={2}>
            <CoursesLegend  filteredCourses={filteredCourses} />
          </Grid>
          

        </Grid>
        <ListCourses openLC={openLC} setOpenLC={setOpenLC}  filteredCourses={filteredCourses} setFilteredCourses={setFilteredCourses}/>
      </Container>
      <Footer/>
    </>
  )
}
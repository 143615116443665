import Api from 'app/helpers/api'

export const courseAttitudesSlice = ((set, get) => ({
  courseAttitudes: [],
  courseAttitudeApplications: [],

  uncompleteAttitudes: [],

  actions: {
    fetchCourseAttitudes: async (params) => {
      const api = new Api();
      api.getCourseAttitudes(params)
        .then((response) => {
          set((state) => ({ 
            courseAttitudes: response.data.course_attitudes, 
            courseAttitudeApplications: response.data.course_attitude_applications
          }));
          get().actions.refreshSelectedAbilities();
        })
      .catch((err) => console.log(err));
    }
  }

}))
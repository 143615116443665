import React, {useRef} from "react"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SimpleBar from "simplebar-react"

import {useTranslation} from "react-i18next"

import AppCircle from "app/components/shared/AppCircle/AppCircle"
import CircleInfo from "app/components/shared/CircleInfo/CircleInfo"
import KnowledgeTick from "app/components/Knowledges/KnowledgeTick/KnowledgeTick"

import useStore from "app/store/store"
import {AbilityFillingStepEnum} from "app/components/shared/enums/global-enums.tsx"

import 'simplebar-react/dist/simplebar.min.css';
import './KnowledgeList.scss'


export default function KnowledgeList({ability, typeFor, srcScrollRef, destScrollRef, disabledPanel}){

  const {t} = useTranslation();
  const knowledges = useStore((state) => state.knowledges);
  const storeActions = useStore((state) => state.actions);
  const refBox= useRef();


  storeActions.setMutualScrollEvent(srcScrollRef, destScrollRef);

  return (
    <Box className="knowledge-list-cover" >
      <Grid container>
        <div className="v-space-4"></div> 
        <Grid item xs={12} className="d-f jc-c ">
          <Typography variant="span"  className="font-1 fourth-color big-title ">
            {t("Knowledge")}
          </Typography>
        </Grid>
        <Grid item xs={12} className="d-f jc-c ab-title-hight">
          <Typography variant="span" className="font-2 fourth-color center-text  sub-title">
            {ability.name}
          </Typography>
        </Grid>
        <Grid item xs={12} className="d-f jc-c turncate">
          <Typography variant="span" className="font-2 second-color-faded-3 sub-title">
            {t("students_will_learn")}
          </Typography>
        </Grid>
        <div className="v-space-4"></div>
        <Grid container item xs={12} className="row-hight">
          <Grid item xs className="d-f " >
            <CircleInfo numbering={1} mainColor={(typeFor === AbilityFillingStepEnum.STEP_1)?  "third-color" : "fifth-color"}  hPadding={2} text={t('To Apply '+typeFor+"s")} />
            <Divider orientation="vertical" />
          </Grid>

          {
            knowledges.map((knowledge, key) => {
              return ( 
                
                <Grid key={key} item xs className="d-f " >
                  <CircleInfo hPadding={1} numbering={key+2} text={t(knowledge.name)} />
                  <Divider orientation="vertical" />
                </Grid>
                
              );
            })
          }
        </Grid>

        <Divider light className="w-100"/>

        <SimpleBar  scrollableNodeProps={{ref: srcScrollRef}}  className="scroll-component-exp" >
          <Grid  container  item xs={12} >
            {
                ((typeFor === AbilityFillingStepEnum.STEP_1)? ability.skills : ability.attitudes)
                .map((elm, key)=> {
                  return (
                    <Box key={key} className="row-hight w-100">
                      <KnowledgeTick  typeFor={typeFor}  elm={elm} refBox={refBox}/>
                      <Divider light className="w-100"/>
                    </Box>
                    );
                })
            }
          </Grid>
        </SimpleBar>

        <div className="v-space-8"></div>
        <Grid ref={refBox} className="d-f jc-c" item xs={12}>
          <AppCircle 
            onClickFunc={() => storeActions.clearAbilityElements(ability)}
            content={<DeleteOutlineOutlinedIcon className="second-color" sx={{fontSize: 18}} />} 
            size={25}
            background={"second-color-faded-2"} />
        </Grid>
      </Grid> 

      { disabledPanel &&
        <div className={"disabled-panel "+typeFor}
          onClick={()=> storeActions.setFillingAbilitiesStep((typeFor === AbilityFillingStepEnum.STEP_1) ? AbilityFillingStepEnum.STEP_1 :AbilityFillingStepEnum.STEP_2)}
        ></div>
      }
    </Box>
    );
}
import React from "react"
import {Link} from "react-router-dom"
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import Grid from '@mui/material/Grid';
import {useTranslation} from "react-i18next"

import AppButton from 'app/components/shared/AppButton/AppButton.tsx'

import {
  ButtonTypeEnum,
  SaveChangesEnum
} from "app/components/shared/enums/global-enums.tsx";
import {AppRoutes} from "app/helpers/routes"

import useStore from "app/store/store";

export default function CourseFooter({course, formRef, saveChangeType=0}){
  
  const {t} = useTranslation();

  
  const storeActions = useStore((state) => state.actions);

  const handleSaveChanges = () => {
    if (SaveChangesEnum.COURSE_ABILITIES === saveChangeType) {
      storeActions.updateCourseAbilities();
      return;
    }

    if (formRef && formRef.current) {
      formRef.current.handleSubmit()
    }
  }




  return (
    <Grid container sx={{pt:1, pb:1}} >
      <Grid  item md={6}>
        <Link to={AppRoutes.dashboardPath}>
          <AppButton
            buttonLabel={t("Back to Dashboard")}
            buttonType={ButtonTypeEnum.DEFAULT}
            buttonStartIcon={<KeyboardDoubleArrowLeftIcon/>}
            sx={{fontWeight:400}}
          />
        </Link>
      </Grid>
      <Grid item md={6} className="d-f jc-r ">


        <AppButton 
          onClickFunc={handleSaveChanges}
          buttonLabel={t('Save changes')}
          buttonType={ButtonTypeEnum.DEFAULT}
        />
        
      </Grid>
    </Grid>
  );

} 
import React,  {useEffect} from "react";

import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import AbilityTick from 'app/components/Abilities/AbilityTick/AbilityTick' 
import AppCircle from "app/components/shared/AppCircle/AppCircle";
import AppButton from "app/components/shared/AppButton/AppButton.tsx";
import ListIcon from '@mui/icons-material/List';
import {
  ButtonTypeEnum
} from "app/components/shared/enums/global-enums.tsx";

import useStore from 'app/store/store'
import "./AbilitiesSideBar.scss"


export default function AbilitiesSideBar({course, parentOnTickClickFunc}) {

  const {t} = useTranslation();

  const sideBarOpen = useStore((state) => state.sideBarOpen);
  const abilities = useStore((state) => state.abilities);
  const storeActions = useStore((state) => state.actions);

	useEffect(() => {
    storeActions.initSideBarOpen(course.id);
	  storeActions.fetchAbilities();
	}, [storeActions, course]);

  function openSideBar() {
    storeActions.toggleSideBar(course.id);
    window.scrollTo({ top: 200, behavior: 'smooth' });
  }
	
  return (
    <>
      
      <Box className={`side-bar-mobile ${(sideBarOpen) ? 'open' : ''}`}
        onClick={openSideBar}  > 
        <ListIcon/>
        {t("Abilities")}
      </Box>

  		<Card className={`abilities-side-bar d-f fd-c jc-sb bloc ${(sideBarOpen) ? 'open' : ''}`}  sx={{ borderRadius: 3 }}>


        <Box  >
  	  		<div className="v-space-3"></div>
          <Box className="center-text">
            {
              !sideBarOpen ? (
            <AppButton
              onClickFunc={()=>storeActions.toggleSideBar(course.id)}
              buttonLabel={">"} 
              buttonType={ButtonTypeEnum.TEXT_ACTIVE}
                />
            ) : (
            <AppButton
              onClickFunc={()=>storeActions.toggleSideBar(course.id)}
              buttonLabel={"<"} 
              buttonType={ButtonTypeEnum.TEXT_ACTIVE}
                /> 
            )}
  	  		</Box>
          <div className="v-space-7"></div>
          <AppCircle 
            addClass="center-el"
            content={
              <Typography variant="span" className="center-text" sx={{fontSize: 15, fontWeight: 700 }} >
                {course.name}
              </Typography>
            } 
            size={127}
            background={"main-color"} />
          
          <div className="v-space-6"></div>
  	  		<div className="v-space-6"></div>
        </Box>
	  		
        <Box   sx={{pl:2, pr:2}}>
          {abilities.map((ability, key) => {
            return (
              <Box key={key}>
                <AbilityTick  
                  sideBarOpen={sideBarOpen} 
                  ability={ability} 
                  parentOnTickClickFunc={parentOnTickClickFunc} 
                  />
                <div className="v-space-1"></div>
              </Box>
            );
          })}
        </Box>

  		</Card>
    </>
  );
}
import React from "react";
import {Link, generatePath, useSearchParams} from "react-router-dom";
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useTranslation } from 'react-i18next';
import {
  ButtonTypeEnum,
  ButtonColorEnum
} from "app/components/shared/enums/global-enums.tsx";
import AppButton from "app/components/shared/AppButton/AppButton.tsx";
import {AppRoutes} from "app/helpers/routes";

import "./CourseHeader.scss"



export default function CourseHeader({course, formRef, detailsButton, visualisationButton}) {

  const {t} = useTranslation();

  let [,setSearchParams] = useSearchParams()

  function handleGoToCourseAbilities() {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }

  function showVisualisation(){
    setSearchParams(params => {
      params.set("visualisation", 1);
      return params;
    });
  }

  return (  
  	<Grid container  sx={{p: 3}}>
  		<Grid item md={5} className="turncate" >
		    	<Typography variant="span" className=" main-color font-2" sx={{fontSize: 32, fontWeight: 700}} >
		    		{course ? course.name: null}
		    	</Typography>
	    </Grid>

	   	<Grid item md>
	   		<Grid container  >
	   			<Grid container  className="d-f jc-r">
            {
              course && course.id &&
              <Link to={generatePath(AppRoutes.courseReportPath, { courseId: course.id})}>
                <AppButton 
                  buttonLabel={t('Course Report')}
                  buttonType={ButtonTypeEnum.DEFAULT}
                  buttonColor={ButtonColorEnum.MAIN_FADED}
                  
                />
              </Link>
            }

	   			  <div className="h-space-1"></div>

            {
              detailsButton &&
              <AppButton
                buttonLabel={t("Proceed to details")}
                buttonType={ButtonTypeEnum.DEFAULT}
                buttonEndIcon={<KeyboardDoubleArrowRightIcon/>}
                sx={{fontWeight:400}}
                onClickFunc={handleGoToCourseAbilities}
              />
            }

            {
              visualisationButton && 
                <AppButton
                  buttonLabel={t("Visualise Course")}
                  buttonType={ButtonTypeEnum.DEFAULT}
                  buttonEndIcon={<KeyboardDoubleArrowRightIcon/>}
                  sx={{fontWeight:400}}
                  onClickFunc={showVisualisation}
                />
            }
            
			    </Grid>
			    
		    </Grid>
	    </Grid>
	    <div className="v-space-2"></div>
	    <Grid item md={12} >
	    	<div className="delimiter"></div>
	    </Grid>

    </Grid>
  );
}

import React from "react"
import { useTranslation } from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import AppCircle from 'app/components/shared/AppCircle/AppCircle';

import useStore from 'app/store/store';
import "./AbilityBox.scss"

export default function AbilityBox({ability, handleParentModalOpen}){

  const {t} = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const selectedAbilities = useStore((state) => state.selectedAbilities);
  const storeActions = useStore((state) => state.actions);

  const abilityNotCompleted = storeActions.isUncompletedAbility(ability);

  function showDetails() {
    searchParams.set("details", 1);
    setSearchParams(searchParams);
  }

  return (
    <Box className="ability-bloc">
      
      {

          selectedAbilities.map((id, key) => {
            if (key === selectedAbilities.length-1) { return (<div key="key"></div>) }
            return (
              <Box key={key} className={"bloc-shades shade-"+(selectedAbilities.length-key)} /> 
            );          
          })
        }

      <Box className="bloc-shades shade-1 pointer" onClick={showDetails}>
        {
          abilityNotCompleted &&
          <div className="uncompleted-indicator">
            
            <AppCircle 
              content={
                <Typography variant="span" className="white-color font-3"
                sx={{fontSize: 40}} >!</Typography>
              }
              size={40}
              background={"main-color-faded-3"} />

          </div>
        }
        <Grid container  rowSpacing={2}>
          <Grid container item md={12}>
            <Grid item >
              <Typography variant="span" className="number" >
                {ability.order_number} 
              </Typography>
            </Grid>
            <Grid item  md className="center-text">
              <Typography variant="span" className="action pointer"   >
                {t('View Details')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item  md={12}>
            <Typography variant="span" className="text">
              {ability.name}
            </Typography>
          </Grid>
        </Grid>
      </Box>

    </Box>
  );

}
import React, {useRef, useEffect} from "react"
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useSearchParams} from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ElmList from "app/components/Abilities/ElmList/ElmList";
import KnowledgeList from "app/components/Knowledges/KnowledgeList/KnowledgeList";
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from "react-i18next"
import useStore from "app/store/store";

import {AbilityFillingStepEnum} from "app/components/shared/enums/global-enums.tsx"
import './AbilityDetails.scss'

export default function AbilityDetails({ability}){
  const {t} = useTranslation();

  const skillScrollRef = useRef();
  const sKnowledgeScrollRef = useRef();
  const aKnowledgeScrollRef = useRef();
  const attitudeScrollRef = useRef();

  const abilities = useStore((state) => state.abilities);
  let [searchParams, setSearchParams] = useSearchParams();

  const fillingAbilitiesStep = useStore((state) => state.fillingAbilitiesStep);
  const storeActions = useStore((state) => state.actions);

  const gottenAbilityId = parseInt(searchParams.get("ability"));
  let gottenAbility;
  let gottenElm = parseInt(searchParams.get("elm"));
  let gottenElmType = searchParams.get("elmType");

  if (abilities.length > 0){
    gottenAbility = abilities.find(ability => ability.id === gottenAbilityId);
  }


  useEffect(() => {
    if (gottenAbility) storeActions.pushAbilityToTop(gottenAbility);
  }, [storeActions, gottenAbility, gottenAbilityId]);


  function hideDetails() {
    searchParams.delete("details");
    setSearchParams(searchParams);
  }

  return (
      <Box  className="modal p-r">
        <div className="close-panel" onClick={hideDetails}> 
          <CloseIcon />
        </div>
        <Grid container className="h-100">
          <div
            onClick={ ()=> storeActions.setFillingAbilitiesStep((fillingAbilitiesStep === AbilityFillingStepEnum.STEP_1) ? AbilityFillingStepEnum.STEP_2 :AbilityFillingStepEnum.STEP_1) }
           className={`mobile-shade-panel ${AbilityFillingStepEnum.STEP_1} ${(fillingAbilitiesStep === AbilityFillingStepEnum.STEP_1)? "disabled-fstep" : ""}`} >
            <div className="h-delimiter"></div>
            <Typography variant="span" className="text font-4 third-color upper" sx={{fontSize: 20, fontWeight: 700}}>
              {t(AbilityFillingStepEnum.STEP_1+"s")}
            </Typography>
            <Typography variant="span" sx={{ zIndex: 400}} className="center-text font-4 third-color upper" >
              <ArrowBackIosIcon sx={{ fontSize: 40, marginLeft: '18px'}}/>
            </Typography>
          </div>
          <div className={`one-panel ${(fillingAbilitiesStep === AbilityFillingStepEnum.STEP_2)? "disabled-fstep" : "active-fstep"}`} >
            <ElmList 

              preSelectedElm={gottenElmType === AbilityFillingStepEnum.STEP_1? gottenElm: null}
              srcScrollRef={skillScrollRef}
              destScrollRef={sKnowledgeScrollRef}
              typeFor={AbilityFillingStepEnum.STEP_1}
              disabledPanel={fillingAbilitiesStep === AbilityFillingStepEnum.STEP_2 ? true : false} 
              ability={ability}/>
          </div>
        
          <div className={`one-panel ${(fillingAbilitiesStep === AbilityFillingStepEnum.STEP_2)? "disabled-fstep" : "active-fstep"}`} >
            <KnowledgeList 
              srcScrollRef={sKnowledgeScrollRef}
              destScrollRef={skillScrollRef}
              ability={ability} 
              typeFor={AbilityFillingStepEnum.STEP_1}
              disabledPanel={fillingAbilitiesStep === AbilityFillingStepEnum.STEP_2 ? true : false} />
          </div>
   
          <div className={`one-panel ${(fillingAbilitiesStep === AbilityFillingStepEnum.STEP_1)? "disabled-fstep" : "active-fstep"}`} >
            <ElmList 
              preSelectedElm={gottenElmType === AbilityFillingStepEnum.STEP_2? gottenElm: null}
              srcScrollRef={attitudeScrollRef}
              destScrollRef={aKnowledgeScrollRef}
              typeFor={AbilityFillingStepEnum.STEP_2}
              disabledPanel={fillingAbilitiesStep === AbilityFillingStepEnum.STEP_1 ? true : false}  ability={ability}/>
          </div>
          <div className={`one-panel ${(fillingAbilitiesStep === AbilityFillingStepEnum.STEP_1)? "disabled-fstep" : "active-fstep"}`} >
            <KnowledgeList 
              srcScrollRef={aKnowledgeScrollRef}
              destScrollRef={attitudeScrollRef}
              ability={ability} 
              typeFor={AbilityFillingStepEnum.STEP_2}
              disabledPanel={fillingAbilitiesStep === AbilityFillingStepEnum.STEP_1 ? true : false} />
          </div>
          <div
            onClick={ ()=> storeActions.setFillingAbilitiesStep((fillingAbilitiesStep === AbilityFillingStepEnum.STEP_1) ? AbilityFillingStepEnum.STEP_2 :AbilityFillingStepEnum.STEP_1) }
           className={`mobile-shade-panel ${AbilityFillingStepEnum.STEP_2} ${(fillingAbilitiesStep === AbilityFillingStepEnum.STEP_2)? "disabled-fstep" : ""}`}>
            <div className="h-delimiter"></div>
            <Typography variant="span" className="text font-4 fifth-color upper" sx={{fontSize: 20, fontWeight: 700}}>
              {t(AbilityFillingStepEnum.STEP_2+"s")}
            </Typography>
            <Typography variant="span" sx={{ zIndex: 400}} className=" font-4 fifth-color upper" >
              <ArrowForwardIosIcon sx={{ fontSize: 40, zIndex: 800}}/>
            </Typography>
          </div>
        </Grid>
      </Box>
    );

}



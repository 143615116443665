import React from "react";
import { Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';

import {useTranslation} from "react-i18next"
import {AbilityFillingStepEnum} from "app/components/shared/enums/global-enums.tsx"

import cssVars from 'app/helpers/_variables.module.scss';


export default function ElmsPageReport({image, selectedAbilities, knowledges, unqCourseElms, courseElms, courseElmApps, typeFor}){

  const {t} = useTranslation();

  let cptElm = 0;

  const styles = StyleSheet.create({
    page: {
      marginTop: 30,
      marginLeft: 30,
      marginRight: 30,
      paddingBottom: 65,
      flexDirection: 'column',
    },
    cWidth: {
      width: '90%'
    },

    defContainer: {
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
      paddingBottom: 8,
      borderLeft: '1 solid '+cssVars['second-color-faded-4'],
      borderRight: '1 solid '+cssVars['second-color-faded-4']
    },

    delimiter: {
      height: "1",
      backgroundColor: cssVars['second-color-faded-4']
    },

    hDelimiter: {
      height: "100%",
      width: "1",
      backgroundColor: cssVars['second-color-faded-4']
    },

    smTitle:{
      fontFamily: 'Bebas',
      fontSize: 12, 
      fontWeight: "bold"
    },
    nmTitle:{
      fontFamily: 'Tinos-reg',
      fontSize: 10
    },
    checkbox:{
      width: 12, 
      height: 12, 
      fontSize: 8, 
      fontFamily: 'Nato-bold', 
      marginRight: 5, 
      color: 'white', 
      textAlign: 'center'
    },
    footerText: {
      textAlign: 'center',
      position: 'absolute',
      fontSize: 10,
      bottom: 40,
      left: 0,
      right: 0,
    }
  });

  let aiColors;
  if (typeFor === AbilityFillingStepEnum.STEP_1){
    aiColors = {colorE: cssVars["third-color-e"], colorI: cssVars["third-color-i"], colorT: cssVars["third-color-t"], colorA: cssVars["fourth-color-faded-3"]}
  }

  if (typeFor === AbilityFillingStepEnum.STEP_2){
    aiColors = {colorE: cssVars["fifth-color-e"], colorI: cssVars["fifth-color-i"], colorT: cssVars["fifth-color-t"], colorA: cssVars["fourth-color-faded-3"]}
  }


  let ml = -90;
  if (window.innerWidth < 1200){
    
    ml = 20;
  }
  if (window.innerWidth < 900){
    ml = 0;
  }

  if (window.innerWidth < 600){
    ml = 60;
  }

  return(
    <Page  style={styles.page}>
      <View style={{...styles.cWidth, ...styles.delimiter}}></View>
      <View style={{...styles.defContainer, ...styles.cWidth}}>
        <Image style={{marginLeft: ml}} src={image} />
        <View style={{position: 'absolute', right: 20, bottom: 20}}>
          <View style={{flexDirection: 'row', marginBottom: 2 }}>
            <Text style={{backgroundColor: '#ccc', ...styles.checkbox}}>{'✓'}</Text>
            <Text style={{fontFamily: 'Tinos-reg', fontSize: 9}}>{t('Applied')}</Text>
          </View>
          <View style={{flexDirection: 'row', marginBottom: 2  }}>
            <Text style={{backgroundColor: aiColors.colorE, ...styles.checkbox}}>{'✓'}</Text>
            <Text style={{fontFamily: 'Tinos-reg', fontSize: 9}}>{t('Expected')}</Text>
          </View>
          <View style={{flexDirection: 'row', marginBottom: 2  }}>
            <Text style={{backgroundColor: aiColors.colorI,...styles.checkbox}}>{'✓'}</Text>
            <Text style={{fontFamily: 'Tinos-reg', fontSize: 9}}>{t('Introduced')}</Text>
          </View>
          <View style={{flexDirection: 'row', marginBottom: 2 }}>
            <Text style={{backgroundColor: aiColors.colorT,...styles.checkbox}}>{'✓'}</Text>
            <Text style={{fontFamily: 'Tinos-reg', fontSize: 9}}>{t('Tought')}</Text>
          </View>
          <View style={{flexDirection: 'row', marginBottom: 2  }}>
            <Text style={{backgroundColor: aiColors.colorA, ...styles.checkbox}}>
              {'✓'}
            </Text>
            <Text style={{fontFamily: 'Tinos-reg', fontSize: 9}}>{t('Assessed')}</Text>
          </View>
        </View>
      </View>

      <View style={{...styles.cWidth, ...styles.delimiter, marginBottom: 20}}></View>

      {

        selectedAbilities.map((ability, key)=>{
          return (
            <View key={key}>
              <View style={{...styles.cWidth, ...styles.delimiter}}></View>
              <View  style={{flexDirection: 'row'}}>
                <View style={{...styles.hDelimiter}} ></View>
                <View style={{width: '296pt', backgroundColor: cssVars['second-color-faded-2']}}>
                  <Text style={{padding:6, fontFamily: 'Bebas', fontSize: 9}}>
                    {ability.name}
                  </Text>
                </View>
                <View style={{...styles.hDelimiter}} ></View>
                <View  style={{width: '236pt'}}>
                  <Text style={{padding:6, textAlign: 'center', fontFamily: 'Bebas', fontSize: 9}}>
                    {t("to_perform_ability")}
                  </Text>
                </View>
                <View style={{...styles.hDelimiter}} ></View>
              </View>

              <View style={{...styles.cWidth, ...styles.delimiter}}></View>
              <View  style={{flexDirection: 'row'}}>
                <View style={{...styles.hDelimiter}} ></View>
                <View style={{width: '255pt'}}>
                  <Text style={{color: cssVars['third-color'], padding:5, fontFamily: 'Bebas', fontSize: 9}}>
                    {t("students_will_trained")}
                  </Text>
                </View>
                <View style={{...styles.hDelimiter}} ></View>
                <View  style={{width: '40pt'}}>
                  <Text style={{padding:5, textAlign: 'center', fontFamily: 'Bebas', fontSize: 9}}>
                    {t("Applied")}
                  </Text>
                </View>
                <View style={{...styles.hDelimiter}} ></View>
                <View  style={{width: '236pt', flexDirection: 'row'}}>
                  {
                    knowledges.map((knowledge, keyKnowledge)=>{
                      return (
                        <React.Fragment key={keyKnowledge}>
                          <View key={keyKnowledge} style={{width: '78pt'}}>
                            <Text style={{padding:5, textAlign: 'center', fontFamily: 'Bebas', fontSize: 9, color: cssVars['fourth-color']}}>
                              {knowledge.name}
                            </Text>
                          </View>
                          {
                            (keyKnowledge < knowledges.length-1) &&
                            <View style={{...styles.hDelimiter}} ></View>
                          }
                        </React.Fragment>
                        )
                    })
                  }
                </View>
                <View style={{...styles.hDelimiter}} ></View>
              </View>
              <View style={{...styles.cWidth, ...styles.delimiter}}></View>
              {
                unqCourseElms.filter((elm)=> elm.ability_id === ability.id).map((elm, key2) => {
                  cptElm++;

                  const applied = courseElmApps.find((cApp)=> cApp[typeFor+"_id"] === elm.id && cApp.applied === true);

                  return(
                  <View key={key2} style={{flexDirection: 'row'}}>
                    <View style={{...styles.hDelimiter}} ></View>
                    <View style={{width: '255pt'}}>
                      <Text style={{padding:5, fontFamily: 'Tinos-reg', fontSize: 9}}>
                        {cptElm}{'. '}{elm.name}
                      </Text>
                    </View>
                    <View style={{...styles.hDelimiter}} ></View>
                    <View  style={{width: '40pt'}}>
                      { applied &&
                      <Text style={{padding:5, textAlign: 'center', fontSize: 10, fontFamily: 'Nato-reg'}}>
                        {'✓'}
                      </Text>
                      }
                    </View>
                    <View style={{...styles.hDelimiter}} ></View>
                    <View  style={{width: '236pt', flexDirection: 'row'}}>
                      {
                        knowledges.map((knowledge, keyKnowledge)=>{

                          const filteredElms = courseElms.filter((courseElm)=> courseElm[typeFor].id === elm.id && courseElm.knowledge?.id === knowledge.id);

                          return (
                            <React.Fragment key={keyKnowledge}>
                              <View key={keyKnowledge} style={{width: '78pt'}}>
                                <Text style={{padding:5, textAlign: 'center', fontFamily: 'Tinos-reg', fontSize: 9}}>
                                  {filteredElms[0]?.instructor_action?.code}
                                  {filteredElms.length>1? "/"+filteredElms[1]?.instructor_action?.code : ""}
                                </Text>
                              </View>
                              {
                                (keyKnowledge < knowledges.length-1) &&
                                <View style={{...styles.hDelimiter}} ></View>
                              }
                            </React.Fragment>
                            )

                        })
                      }
                    </View>
                    <View style={{...styles.hDelimiter}} ></View>
                  </View>
                    )
              })
              }

            </View>
          )
        })
      }
      <View style={{...styles.cWidth, ...styles.delimiter}}></View>
      <Text fixed style={{...styles.footerText,...styles.cWidth}}>
        {t("Generated by thewhitespaces.co")}
      </Text>
    </Page>
  )
}
import React from "react"

import Box from '@mui/material/Box'

import cssVars from "app/helpers/_variables.module.scss";
import './AppCircle.scss'

export default function AppCircle({content, size, background, color,addClass, onMouseEnterFunc, onMouseLeaveFunc, onClickFunc}){

  return (
    <Box 

      onClick={onClickFunc}
      sx={{
        width: size,
        height: size,
        minWidth: size,
        bgcolor: cssVars[background]||background,
        color: cssVars[color]
        }}
        
      className={`app-circle ${addClass}`}

       >
      {content}
    </Box>
    );

}
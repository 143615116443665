import React from "react";
import AppButton from "app/components/shared/AppButton/AppButton.tsx";
import groupPhoto from "../../../assets/images/group-photo.svg";
import "./Hero.scss";
import { ButtonTypeEnum } from "app/components/shared/enums/global-enums.tsx";
import { RocketLaunch } from "@mui/icons-material";

export default function Hero() {
  return (
    <div className="d-flex-row hero-showcase">
      <div className="hero-left-content">
        <h1 className="heading">
          Nurture <span className="design">Design</span> Competencies
        </h1>
        <p>
          Building competent individuals with courses, curriculums,
          collaboration and a whole lot of convenience!
        </p>
        <div className="d-flex-row hero-cta-container">
          <AppButton
            onClickFunc={() => {}}
            buttonLabel={"Get Started"}
            buttonType={ButtonTypeEnum.PRIMARY}
            buttonStartIcon={<RocketLaunch />}
          />
          <AppButton
            onClickFunc={() => {}}
            buttonLabel={"How it works"}
            buttonType={ButtonTypeEnum.TEXT_OUTLINE}
          />
        </div>
      </div>
      <div>
        <img src={`${groupPhoto}`} alt="groupphoto" />
      </div>
    </div>
  );
}

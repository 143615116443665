import React, {useState} from "react"
import {Link, generatePath} from "react-router-dom";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AppCircle from "app/components/shared/AppCircle/AppCircle";


import useStore from 'app/store/store';
import {AppRoutes} from "app/helpers/routes";
import "./CourseItem.scss";


import { Sketch} from '@uiw/react-color';

import variables from "app/helpers/_variables.module.scss";


export default function CourseItem({course}){

  const storeActions = useStore((state) => state.actions);

  const [hex, setHex] = useState(course.color);


  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    course.color = hex;
    storeActions.updateOrCreateCourse({course: course}, true);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  return (
      <Box className="course-item-container w-100" sx={{backgroundColor: (course.input_type_id !== 1 ? variables["main-color-faded"] : null)}} > 
        <Box className="pointer d-f jc-sb " sx={{p:2}} >
          <Link className="turncate" to={generatePath(AppRoutes.courseAbilitiesPath, { courseId: course.id})}>
          <Typography variant="span" style={{color: hex}} className="course-name font-2 turncate">
            {
              course.input_type_id === 1 &&
              <span className='record-type bg-main-color' >C</span> 
            }
            {course.name}
          </Typography>
          </Link>
          <AppCircle 
            onClickFunc={handleClick}
            size={22}
            background={hex} />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Sketch
              color={hex}
              onChange={(color) => {
                setHex(color.hex);
              }}
            />
          </Popover>

        </Box>
        <Divider className="w-100"/>
        <Box sx={{p:2}} className="d-f jc-sb">

          <div onClick={() => {
            storeActions.setCurrentCourse(course)
            storeActions.setOpenConfAlert(true)}}>
            <Typography variant="span" className=" small-text pointer">
              <DeleteOutlineOutlinedIcon className="second-color font-3" sx={{fontSize: 18}} />
            </Typography>
          </div>
        </Box>
      </Box>
    );
}

import React from "react";
import Footer from "app/components/shared/Footer/Footer";
import Navbar from "app/components/shared/Navbar/Navbar.tsx";
import Contact from "./Contact/Contact.jsx";
import Features from "./Features/Features.tsx";
import Hero from "./Hero/Hero";

export default function Home() {
  return (
    <>
      <Navbar />
      <div className="container">
        <Hero />
        <div className="v-space-10"></div>
        <Features />
        <div className="v-space-10"></div>
        <Contact />
        <Footer />
      </div>
    </>
  );
}

import React from "react";
import "./Footer.scss";

export default function Footer() {

  const d = new Date();
  let year = d.getFullYear();

  return (
    <div className="d-flex-row reserve">
      <p>© {year} Competency Edge </p>
   
    </div>
  );
}

import React from "react"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import AddIcon from "@mui/icons-material/Add"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import SimpleBar from "simplebar-react"

import {useTranslation} from "react-i18next"

import CircleInfo from "app/components/shared/CircleInfo/CircleInfo"
import AddAbilityElement from "app/components/Abilities/AddAbilityElement/AddAbilityElement"
import ElmTick from "app/components/Abilities/ElmTick/ElmTick"
import AppButton from "app/components/shared/AppButton/AppButton.tsx"
import {ButtonTypeEnum, ButtonColorEnum, ButtonSizeEnum, AbilityFillingStepEnum} from "app/components/shared/enums/global-enums.tsx"

import useStore from 'app/store/store'

import 'simplebar-react/dist/simplebar.min.css';
import './ElmList.scss'


export default function ElmList({ability, disabledPanel, srcScrollRef, destScrollRef, preSelectedElm, typeFor}){

  const {t} = useTranslation();
  const fillingAbilitiesStep = useStore((state) => state.fillingAbilitiesStep);
  const isFillingNewElm = useStore((state) => state.isFillingNewElm);
  const storeActions = useStore((state) => state.actions);

  storeActions.setMutualScrollEvent(srcScrollRef, destScrollRef);


  function handleDone(){
    storeActions.setFillingAbilitiesStep((typeFor === AbilityFillingStepEnum.STEP_1? AbilityFillingStepEnum.STEP_2 : AbilityFillingStepEnum.STEP_1 ));
    if (typeFor === AbilityFillingStepEnum.STEP_2){
      storeActions.goNextAbilityOrSave(); 
    }
  }

  return (
    <Box className="elm-list-cover ">
      <Grid container>
        <div className="v-space-4"></div> 
        <Grid item xs={12} className="d-f jc-c ">
          <Typography variant="span"  className={"font-1 big-title "+(typeFor ===  AbilityFillingStepEnum.STEP_1? "third-color": "fifth-color" )}>
            {t(typeFor+"s")}
          </Typography>
        </Grid>
        <Grid item xs={12} className="d-f jc-c ab-title-hight">
          <Typography variant="span" className={"font-2 sub-title center-text "+(typeFor ===  AbilityFillingStepEnum.STEP_1? "third-color": "fifth-color" )} >
            {ability.name}
          </Typography>
        </Grid>
        <Grid item xs={12} className="d-f jc-c turncate">
          <Typography variant="span" className="font-2 second-color-faded-3 sub-title">
            {t("students_will_trained")}
          </Typography>
        </Grid>
        <div className="v-space-4"></div>

        {
          (isFillingNewElm && typeFor === fillingAbilitiesStep ) && <AddAbilityElement />
        }
        
        {
          !(isFillingNewElm && typeFor === fillingAbilitiesStep ) && 
          <>
            <Grid container className="d-f jc-sb ai-c row-hight" item xs={12}>
              <Grid item xs={2}>
                <CircleInfo numbering={"?"} text={t("Relevant")} />
              </Grid>
              <Grid sx={{paddingRight:1}} className="d-f ai-c">
                <AppButton 
                  buttonType={ButtonTypeEnum.TEXT_OUTLINE} 
                  buttonLabel={t("Add "+typeFor+"s")}
                  buttonStartIcon={<AddIcon />} 
                  buttonSize={ButtonSizeEnum.SMALL}
                  buttonColor={(typeFor ===  AbilityFillingStepEnum.STEP_2?ButtonColorEnum.FIFTH_FADED : null)}
                  onClickFunc={storeActions.toggleFillingNewElm}
                  />
              </Grid>
            </Grid>
            <Divider light className="w-100"/>
            <SimpleBar scrollableNodeProps={{ref: srcScrollRef}}  className="scroll-component-exp" >
              <Grid item xs={12} sx={{paddingRight:1}}>
                {
                  ((typeFor === AbilityFillingStepEnum.STEP_1)? ability.skills : ability.attitudes).map((elm, key)=> {
                    return (
                      <Box key={key} className={"row-hight "+(preSelectedElm===elm.id? 'bg-second-color-faded-2': '')}>
                        <ElmTick typeFor={typeFor} elm={elm}/>
                        <Divider light className="w-100"/>
                      </Box>
                      );
                  }
                )}
              </Grid>
            </SimpleBar>

            <div className="v-space-9"></div>
            <Grid className="d-f jc-c" item xs={12}>
              <AppButton buttonType={ButtonTypeEnum.PRIMARY} buttonLabel={t("Done!")}
                buttonColor={(typeFor ===  AbilityFillingStepEnum.STEP_1?ButtonColorEnum.THIRD : ButtonColorEnum.FIFTH)}
                buttonSize={ButtonSizeEnum.SMALL}
                onClickFunc={handleDone}
               />
            </Grid>
          </>
        }
      </Grid> 

      { disabledPanel &&
        <div className={"disabled-panel "+typeFor}
          onClick={()=> storeActions.setFillingAbilitiesStep((typeFor === AbilityFillingStepEnum.STEP_1) ? AbilityFillingStepEnum.STEP_1 :AbilityFillingStepEnum.STEP_2)}
        ></div>
      }

    </Box>
    );
}
import React from "react"
import {Link, generatePath} from "react-router-dom";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import {AppRoutes} from "app/helpers/routes";
import "./ProgramItem.scss";


export default function ProgramItem({program}){

  return (
      <Box className="program-item-container w-100"  > 
        <Box className="pointer d-f jc-sb " sx={{p:2}} >
          <Link className="turncate" to={generatePath(AppRoutes.dashboardPath)+"?program_id="+program.id} >
            <span className="program-name font-2 second-color turncate">
              {program.name}
            </span>
          </Link>

        </Box>
        <Divider className="w-100"/>
        <Box sx={{p:2}} className="d-f jc-sb">

        </Box>
      </Box>
    );
}

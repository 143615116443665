import { create } from 'zustand'

const useChangesWatcherStore = create((set) => ({
  unsavedChanges: false,
  functions: {
    setUnsavedChanges: async (val) => {
      set((state) => ({ unsavedChanges: val }));
    }
  },
}))

export default useChangesWatcherStore; 
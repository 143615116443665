import React from "react"
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import {AbilityFillingStepEnum} from "app/components/shared/enums/global-enums.tsx"
import {useTranslation} from 'react-i18next'
import useStore from 'app/store/store'

import './TickItems.scss'

export default function TickItems (){

  const {t} = useTranslation();
  const fAS = useStore((state) => state.fillingAbilitiesStep);
  const visualisation = useStore((state) => state.visualisation);
  const instructorActions = [...useStore((state) => state.instructorActions)];

  let sortedIA = [];
  if (instructorActions.length>0){
    sortedIA = instructorActions.slice(0,-1).sort((a, b) => {return a.name.localeCompare(b.name) });;
    sortedIA.push(instructorActions[instructorActions.length-1])
  }
  const storeActions = useStore((state) => state.actions);

  function checkItem(event, step){
    event.preventDefault();
    storeActions.setFillingAbilitiesStep(step);
  }

  function SubItems(){
    return (
      <>
      <Grid>
        <Grid  className="d-f ai-c">
          <Checkbox sx={{p:0}} className="ch-faded " checked={visualisation.applied} onClick={() => storeActions.toggleVisIA("applied")}   />
           
          <Typography variant="span" sx={{fontSize: 12, fontWeight: 400}} className="fifth-color fifth-color font-2 ">
            {t("Applied")}
          </Typography>
        </Grid>
        
      </Grid>

      { 
        sortedIA.map((IA, key)=>{
          return(
          <Grid key={key} className="d-f ai-c">
            
            <Checkbox sx={{p:0}} checked={visualisation["op"+IA.code]} className={"ch-"+fAS[0].toLowerCase()+IA.code.toLowerCase()}   onClick={() => storeActions.toggleVisIA("op"+IA.code)}   />
            
            <Typography variant="span" sx={{fontSize: 12, fontWeight: 400}} className="fifth-color font-2 ">
              {IA.name}
            </Typography>
          </Grid>
          )
        })
      }
      </>
    );
  }

  return (
    <Grid className="d-f fd-r">
      <Grid className="min-width">
        <Grid className="d-f ai-c">
          
          <Checkbox sx={{p:0}}  className="ch-third" checked={!!(fAS === AbilityFillingStepEnum.STEP_1)}   onClick={(event) => checkItem(event, AbilityFillingStepEnum.STEP_1)}    /> 
          
          <Typography variant="span" sx={{fontSize: 14, fontWeight: 700}} className="second-color font-1 ">
            {t("Skills")}
          </Typography>

        </Grid>

        { fAS === AbilityFillingStepEnum.STEP_1 && <SubItems /> }
      </Grid>
      <Grid className="min-width">
        <Grid className="d-f ai-c">
          
          <Checkbox sx={{p:0}}  className="ch-fifth" checked={!!(fAS === AbilityFillingStepEnum.STEP_2)} onClick={(event) => checkItem(event, AbilityFillingStepEnum.STEP_2)}  />
          
          <Typography variant="span" sx={{fontSize: 14, fontWeight: 700}} className="second-color font-1 ">
            {t("Attitudes")}
          </Typography>


        </Grid>
        { fAS === AbilityFillingStepEnum.STEP_2 && <SubItems /> }
      </Grid>
    </Grid>
    )
}
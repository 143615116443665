import {
  Grid,
  Typography,
  Container
} from "@mui/material";
import feature1 from "app/assets/images/feature1.jpg"
import feature2 from "app/assets/images/feature2.jpg"
import feature3 from "app/assets/images/feature3.jpg"
// import feature3 from "feature3.jpg"
// import AppButton from "app/components/shared/AppButton/AppButton.tsx";
// import { ButtonTypeEnum } from "app/components/shared/enums/global-enums.tsx";
import React from "react";
import "../Features/Features.scss";

export default function Features() {
  return (
    <Container >
      <div className="feature-container">
        <div className="d-flex-column">
          <Typography variant="h3" className="title">
            Bespoke educational services using our unique AI-based competency framework
          </Typography>
        </div>
        <div className="v-space-10"></div>
        <Grid container className="feature-grid-container jc-sa ai-c my-5">
          
              <div>
                <Grid container spacing={4}>

                  <Grid item xs={6} container >
                    <Grid className="feature-title">
                      1. Consulting services
                    </Grid>
                    <Grid className="feature-content " >
                      <u>Course & Program Analysis:</u>
                        <ul>
                          <li>Alignment of planned/addressed competencies to objectives, learning outcomes, accreditation requirements and employer needs</li>
                          <li>Gap analysis</li>
                          <li>Program coherence and progression of learning?</li>
                        </ul>
                      <u>Course & Program co-development:</u>
                        <ul>
                          <li>Identification of improvement potential and possible measures</li>
                        </ul>
                      <u>Student learning analysis:</u>
                        <ul>
                          <li>Alignment of intended learning with students’ perceived learning of competencies</li>
                          <li>Analysis of progression</li>
                        </ul>
                      <span className="error-color font-1">Target audience:</span><br/>
                      Educators and program developers at universities and schools, training organisations, and HR departments 
                    </Grid>

                  </Grid>
                  <Grid item xs={6} container >
                    <div style={{width:'100%', backgroundRepeat: 'no-repeat', height: '100%', backgroundSize: 'cover', backgroundImage:"url("+feature1+")"}}></div>
                  </Grid>
                </Grid>
              </div>

              <div className="v-space-10"></div>
              <div className="v-space-10"></div>
              <div className="v-space-10"></div>


              <div>
                <Grid container spacing={4}>
                  <Grid item xs={6} container >
                    <div style={{width:'100%', height: '100%', backgroundImage:"url("+feature2+")"}}></div>
                  </Grid>

                  <Grid item xs={6} container >
                    <Grid className="feature-title">
                      2. Bespoke, hands-on competency workshops
                    </Grid>
                    <Grid className="feature-content " >
                      <u>Competency identification and mapping</u> 
                      <ul>
                        <li>Introduction to competencies</li>
                        <li>Identification and formulation of competencies for one’s own course</li>
                      </ul>
                        
                      <u>Gap analysis</u> 
                      <ul>
                        <li>Identification of misalignment of competencies and learning outcomes at course and program level</li>
                      </ul>

                      <u>Course & program improvement measures</u> 
                      <ul>
                        <li>Identification of improvement potential and possible measures</li>
                      </ul>

                      <span className="error-color font-1">Target audience:</span><br/>
                      Educators, program developers, trainers, accreditation institutions representatives, future employers 


                    </Grid>
                  </Grid>
                  
                </Grid>
              </div>


              <div className="v-space-10"></div>
              <div className="v-space-10"></div>
              <div className="v-space-10"></div>

              <div>
                <Grid container spacing={4}>

                  <Grid item xs={6} container >
                    <Grid className="feature-title">
                      <div className="v-space-10"></div>
                      3. Design approaches workshops
                    </Grid>
                    <Grid className="feature-content " >
                      Introduction of design methods to foster design competencies in design and non-design courses.<br/>

                      <div className="v-space-10"></div>
                      <span className="error-color font-1">Target audience:</span><br/>
                      Educators interested in developing a design course or are interested in introducing a designerly approach in their non-design course

                      <div className="v-space-10"></div>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} container >
                    <div style={{width:'100%', height: '100%', backgroundPosition: 'center', backgroundImage:"url("+feature3+")"}}></div>
                  </Grid>
                </Grid>
              </div>
            
        </Grid>
      </div>
    </Container>
  );
}

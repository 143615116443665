import React, {useRef, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Container from '@mui/material/Container';

import CourseHeader from "app/components/Courses/CourseHeader/CourseHeader";
import CourseForm from "app/components/Courses/CourseForm/CourseForm";
import Footer from "app/components/shared/Footer/Footer";
import Navbar from "app/components/shared/Navbar/Navbar.tsx";
import CourseFooter from "app/components/Courses/CourseFooter/CourseFooter";

import Api from "app/helpers/api";

export default function Home() {

  const [course, setCourse] = useState({})

  const formRef = useRef()

  let { courseId } = useParams();

  useEffect(() => {
    if (courseId){
      getCourseDetails({courseId: courseId});
    }
  }, [courseId]);

  const getCourseDetails = async(params) => {
    const api = new Api();
    api.getCourse(params)
      .then((response) =>
        setCourse(response.data.course)
      )
    .catch((err) => console.log(err));
  }


  return (
  	<>
  		<Navbar/>
	    <Container maxWidth="xl">
        <CourseHeader formRef={formRef} detailsButton={courseId? true : false}/>
        <CourseForm course={course} formRef={formRef}/>
	      <CourseFooter formRef={formRef}  course={course}/>
        <div className="v-space-10"></div>
	    </Container>

	    <Footer/>
    </>
  );
}
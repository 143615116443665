import {AbilityFillingStepEnum, CollaborateViewEnum} from "app/components/shared/enums/global-enums.tsx"

export const utilsSlice = ((set, get) => ({
  sideBarOpen: true,
  // abilityDetailesOpen: false,

  snackMessageShown: false,
  successMessage: null,
  errorMessage: null,
  infoMessage: null,
  warningMessage: null,
  isPendingMessage: false,
  snackMessageTimeout: null,


  fillingAbilitiesStep: AbilityFillingStepEnum.STEP_1,

  collaborateView: CollaborateViewEnum.VIEW_1,

  isFillingNewElm: false,

  openConfAlert: false,

  visualisation: {
    applied: true,
    opE: true,
    opI: true,
    opT: true,
    opA: true
  },



  navigate: undefined,

  skillImageData: null,
  attitudeImageData: null,

  actions: {

    setCollaborateView: (view) => {
      set((state) => ({ collaborateView: view}));
    },

    setSkillImageData: (data) => {
      set((state) => ({ skillImageData: data}));
    },
    setAttitudeImageData: (data) => {
      set((state) => ({ attitudeImageData: data}));
    },

    toggleVisIA(optionCode){
      let visualisation = {...get().visualisation};
      visualisation[optionCode] = !visualisation[optionCode];

      set((state) => ({ visualisation: visualisation}));
    },

    setOpenConfAlert: (open) => {
      set((state) => ({ openConfAlert: open}));
    },

    setMutualScrollEvent: (srcScroll, destScroll) => {
      if (srcScroll.current ){
        srcScroll.current.scrollTop = 0;
        srcScroll.current.addEventListener('scroll', (event)=>{
          if (event.currentTarget && destScroll.current)
          destScroll.current.scrollTop = event.currentTarget.scrollTop;
        });
      }
    },

    toggleFillingNewElm: () => {
      set((state) => ({ isFillingNewElm: !get().isFillingNewElm}));
    },
    setFillingAbilitiesStep: (step) => {
      set((state) => ({ fillingAbilitiesStep: step, isFillingNewElm:false}));
    },
    setNavigate: (navigate) => {
      set((state) => ({ navigate: navigate }));
    },
    initSideBarOpen: (courseId) => {
      let cachedSideBarOpen = get().actions.retrieveItem("sideBarOpen-"+courseId);
      if (cachedSideBarOpen === null) cachedSideBarOpen = true;
      set((state) => ({ sideBarOpen: !!cachedSideBarOpen }));
    },
    toggleSideBar: (courseId) => {
      set((state) => ({ sideBarOpen: !get().sideBarOpen}));
      get().actions.storeItem("sideBarOpen-"+courseId, get().sideBarOpen);
    },
    // toggleAbilityDetailes: () =>{
    //   set((state) => ({ abilityDetailesOpen: !get().abilityDetailesOpen }));
    // },

    setSnackMessage: (params) =>{
      clearTimeout(get().snackMessageTimeout);

      if (params.show){
        const timeout = setTimeout(function () {
          get().actions.setSnackMessage({show: false})
        }, 6000);
        set((state) => ({snackMessageTimeout: timeout}));
      }
      

      let newState = {
          snackMessageShown: params.show, 
          successMessage: null,
          infoMessage: null,
          errorMessage: null,
          warningMessage: null, 
          isPendingMessage: !!params.isPendingMessage
          };
      
      if (params.type === 'SUCCESS'){
          newState.successMessage = params.text;
      }
      if (params.type === 'INFO'){
          newState.infoMessage = params.text;
      }
      if (params.type === 'ERROR'){
          newState.errorMessage = params.text;
      }
      if (params.type === 'WARNING'){
          newState.warningMessage = params.text;
      }
      set((state) => (newState));
    },
  }

}))
import axios from "axios";
import useStore from "app/store/store";

export default class Api {
  constructor() {
    this.access_token = null;
    this.client = null;
    this.apiUrl = process.env.REACT_APP_API_ENDPOINT;
  }
  init = () => {
    this.access_token = useStore.getState().actions.retrieveItem("user-access-token");
    let headers = {
      Accept: "application/json",
    };
    if (this.access_token) {
      headers.Authorization = `Bearer ${
        this.access_token
      }`;
    }
    this.client = axios.create({
      baseURL: this.apiUrl,
      timeout: 31000,
      headers: headers,
    });
    return this.client;
  };

  reloadUser = (data) => {
    return this.init().get('/users/reload');
  };
  signUp = (data) => {
    return this.init().post("/users", data);
  };
  signIn = (data) => {
    return this.init().post("/sessions", data);
  };
  logout = (data) => {
    return this.init().delete("/users/logout");
  };
  getCourses = (data) => {
    const prmStr = new URLSearchParams(data).toString();
    return this.init().get("/courses?"+prmStr);
  };
  addCourse = (data) => {
    return this.init().post("/courses", data);
  };
  updateCourse = (data) => {
    return this.init().put(`/courses/${data.courseId}`, data);
  };
  getCourse = (data) => {
    return this.init().get(`/courses/${data.courseId}`);
  };
  deleteCourse = (data) => {
    return this.init().delete(`/courses/${data.courseId}`);
  };
  getCourseSkills = (data) => {
    return this.init().get(`/courses/${data.courseId}/skills`);
  };
  getCourseAttitudes = (data) => {
    return this.init().get(`/courses/${data.courseId}/attitudes`);
  };
  getAbilities = (data) => {
    return this.init().get(`/abilities`);
  };
  getKnowledges = (data) => {
    return this.init().get(`/knowledges`);
  };
  getDisciplines = (data) => {
    return this.init().get(`/disciplines`);
  };
  getPrograms = (data) => {
    return this.init().get(`/programs`);
  };
  getInputTypes = (data) => {
    return this.init().get(`/input_types`);
  };
  getUniversities = (data) => {
    return this.init().get(`/universities`);
  };
  getAcademicYears = (data) => {
    return this.init().get(`/academic_years`);
  };
  getAcademicTerms = (data) => {
    return this.init().get(`/academic_terms`);
  };
  getInstructorActions = (data) => {
    return this.init().get(`/instructor_actions`);
  };
  updateCourseSkills = (data) => {
    return this.init().put(`/courses/${data.courseId}/skills`, data);
  };
  updateCourseAttitudes = (data) => {
    return this.init().put(`/courses/${data.courseId}/attitudes`, data);
  };
  addSkill = (data) => {
    return this.init().post(`/abilities/${data.abilityId}/skills`, data);
  };
  addAttitude = (data) => {
    return this.init().post(`/abilities/${data.abilityId}/attitudes`, data);
  };
}

import Api from 'app/helpers/api'

export const instructorActionsSlice = ((set, get) => ({
  instructorActions: [],

  actions: {
    fetchInstructorActions: async (params) => {
      const api = new Api();
      api.getInstructorActions()
        .then((response) => {
          set((state) => ({ instructorActions: response.data.instructor_actions }));
        })
      .catch((err) => console.log(err));
    },

  }

}))